import React, { useState, useEffect, useLayoutEffect } from "react";
import styled from "styled-components";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import COLORS from "../../../assets/colors";
import CustomizedTables from "../../Common/CustomizedTables";
import TabCustom from "../../Common/TabCustom";
import TabPanel from "../../Common/TabPanel";
import {
  Skill_ContentItems_Table_Relevancy,
  Skill_Persona_Table,
  Skill_Role_Table,
} from "../ArchitectSkills/SkillContent/SkillContentTable";
import { Skill_Update_Proficiencies_Table } from "./SkillMaintainTableElements";
import useAlert from "../../../Hooks/useAlert";
import { PrimaryButton } from "../../Common/Buttons/PrimaryButton";
import ConfirmationButton from "../../Common/ConfirmationButton";
import { SortString } from "./../../../helpers/SortString";
import { SkillService } from "../../../services/SkillService";
import { RoleServices } from "../../../services/RoleServices";
import { AuthService } from "../../../services/AuthService";
import PaginationCustom, { CountInit } from "../../Common/PaginationCustom";
import { H5 } from "../../Common/Typography";
import { FilterTableRow } from "../../../helpers/FilterTableRow";
import RowCount from "../../Common/Others/RowCount";
import useAuth from "../../../Hooks/useAuth";
import { useSkillTableData } from "../../../Provider/SkillTableProvider";
import ModalPopup from "../../Common/ModalPopup";
import SkillContentDetail from "./SkillContentDetail";
import SkillAssessment from "./SkillAssessment";
import { useTableContext } from "../../../Provider/TableProvider";
import DotLoader from "../../Common/Loader/DotLoader";
import { getContentSkillRelevancyStatus } from "../../../enums/ContentSkillRelevancy";
import { SecondaryButton } from "../../Common/Buttons/SecondaryButton";
import AIGenerator from "../../Common/AIGenerator";
import { AIGeneratorType } from "../../../enums/AIGeneratorType";
import AutoSelectBox from "../../Common/FormElements/AutoSelectBox";
import useFullLoader from "../../../Hooks/useFullLoader";
import { AIStore } from "../../../store/store_state";
import { SourceType } from "../../../enums/SourceType";
import usePopUp from "../../../Hooks/usePopUp";
import text from "../../../locale.en.json";


const tabTitle = [
  "Proficiencies",
  "Content",
  "Roles",
  "Personas",
  "Assessments",
];

let selectedContentItemsContainer = [];
let selectedRelevancyItemContainer = {};

export default ({ ...props  }) => {
  const { id, detailData, skillDetail } = props;
  const { showAlert } = useAlert();
  const { setSortAndFilterReset } = useTableContext();

  const { setFullLoader } = useFullLoader();
  const { userDetails } = useAuth();
  const { User_Type } = userDetails;
  const {
    contentDetailModal,
    setContentDetailModal
  } = useSkillTableData();

  const [currentTab, setCurrentTab] = useState(0);
  const [tabList, setTabList] = useState([]);
  const [proficiencyTableColumn, setProficiencyTableColumn] = useState(
    Skill_Update_Proficiencies_Table
  );
  const [roleTableColumn, setRoleTableColumn] = useState(Skill_Role_Table);
  const [proficiencyArray, setProficiencyArray] = useState(null);
  const [skillRoles, setSkillRoles] = useState(null);
  const [confirmPopup, setConfirmPopup] = useState(false);
  const [skillRolesCopy, setSkillRolesCopy] = useState(null);
  const [skillContentItems, setSkillContentItems] = useState(null);
  const [skillContentItemsCopy, setSkillContentItemsCopy] = useState(null);
  const [levelMenu, setLevelMenu] = useState(null);
  const [levelMenuRoles, setLevelMenuRoles] = useState([]);
  const [subSectors, setSubSectors] = useState(null);
  const [functionList, setFunctionList] = useState([]);
  const [occupationList, setOccupationList] = useState([]);
  const [skillPersonas, setSkillPersonas] = useState(null);
  const [skillPersonasCopy, setSkillPersonasCopy] = useState(null);
  const [skillAssessmentList, setSkillAssessmentList] = useState(null);
  const [skillAssessmentListCopy, setSkillAssessmentListCopy] = useState(null);
  const [currentProRow, setCurrentProRow] = useState(null);
  const [currentContentItemRow, setCurrentContentItemRow] = useState(null);
  const [currenRoleRow, setCurrenRoleRow] = useState(null);
  const [profiTableHeight, setProfiTableHeight] = useState("auto");
  const [roletableHeight, setRoletableHeight] = useState("auto");
  const [showIrrelevantCourses, setShowIrrelevantCourses] = useState(false);
  const [contentSort, setContentSort] = useState(null);
  const [contentFilters, setContentFilters] = useState(null);
  const [contentLoading, setContentLoading] = useState(false);
  const [assessmentGenerated, setAssessmentGenerated] = useState(false);
  const [warningPopUp, setWarningPopUp] = useState(false);
  const [isRelevant, setIsRelevant] = useState(false);
  const [showDescriptionLevelPopUp, setShowDescriptionLevelPopUp] = useState({
    proficiencyDescriptionLevel: false,
    knowledgeDescriptionLevel: false
  });
  const aiSuggestedProficienyDescription = AIStore.useState((s) => s.aiSuggestedProficienyDescription);
  const aiSuggestedKnowledgeDescription = AIStore.useState((s) => s.aiSuggestedKnowledgeDescription);
  const skillSourceId = detailData?.skillSource?.sourceId;
  const { popUp } = usePopUp();

  const handleTab = (val) => {
    setCurrentProRow(null);
    setCurrenRoleRow(null);
    setCurrentContentItemRow(null);
    setCurrentTab(val);
    updateReset();
  };

  const handleRelevancyButton = (data) => {
    let isContentSelected = selectedContentItemsContainer?.length > 0;
    if(!showIrrelevantCourses && isContentSelected && data === "relevant"){
        isContentSelected = Object.values(selectedRelevancyItemContainer).includes('Not verified');
    }
    const handleClick = () => {
        setIsRelevant(data === "relevant")
        setConfirmPopup(true);
    };

    return (
        <SecondaryButton
            onClick={handleClick}
            className={`more_action`}
            disabled={!isContentSelected}
        >
            {`Mark selected content as ${data}`}
        </SecondaryButton>
    );
};


  useEffect(() => {
    User_Type === 3 ? setTabList(["Proficiencies"]) : setTabList(tabTitle);
  }, [User_Type]);

  useLayoutEffect(() => {
    if (!!document.querySelector(".MuiTableBody-root tr.MuiTableRow-root")) {
      if (currentTab === 2) {
        setRoletableHeight(
          (document.querySelector(".MuiTableBody-root tr.MuiTableRow-root")
            .offsetHeight +
            8) *
            10 +
            (document.querySelector(".MuiTableRow-head").offsetHeight + 8) +
            8
        );
      } else if (currentTab === 0) {
        setProfiTableHeight(
          (document.querySelector(".MuiTableBody-root tr.MuiTableRow-root")
            .offsetHeight +
            8) *
            10 +
            (document.querySelector(".MuiTableRow-head").offsetHeight + 8) +
            8
        );
      }
    }
  }, [currentTab]);

  // Pagination
  const [pageOffset, setPageOffset] = useState(0);
  const [rowCount, setrowCount] = useState(CountInit);
  const handlePagination = (e, page) => setPageOffset((page - 1) * rowCount);
  const changeRowCount = (count) => setrowCount(count);

  useEffect(() => {
    getContentItems(rowCount, pageOffset, showIrrelevantCourses, false);
  }, [pageOffset, rowCount]);

  useEffect(() => {
    setPageOffset(0);
    getContentItems(rowCount, 0, showIrrelevantCourses, true);
  }, [contentSort, contentFilters, showIrrelevantCourses]);

  const getContentItems = async (
    rowCountX,
    pageOffsetX,
    showIrrelevant,
    contentLoad
  ) => {
    setContentLoading(!!contentLoad);
    try {
      const resContentItems = await SkillService.getSkillContentItemsV3(
        "/" + Number(id),
        "/contentItems",
        `?pagesize=${rowCountX}`,
        `&offset=${pageOffsetX}`,
        ``,
        `&showIrrelevantCourses=${showIrrelevant}`,
        !!contentSort
          ? `&sortBy=${contentSort?.sortBy || ""}&sortOrder=${
              contentSort?.sortOrder || ""
            }`
          : "",
        !!contentFilters ? contentFilters : ""
      );

      const updateData = {
        ...resContentItems,
        contentDetailList: resContentItems?.contentDetailList.map((el) => ({
          ...el,
          relevancy: getContentSkillRelevancyStatus(el.relevancy),
          checked: selectedContentItemsContainer?.includes(el.contentId)
        })),
      };
      setSkillContentItemsCopy(updateData);
      setSkillContentItems(updateData);
      setContentLoading(false);
    } catch (error) {
      console.log(error);
    } finally {
      setContentLoading(false);
    }
  };

  // ContentItems Edit
  const handleContentItemEdit = (e, item, index) => {
    e.stopPropagation();
    if (currentContentItemRow === null) {
      setCurrentContentItemRow(index);
    } else {
      showAlert("Kindly save the existing Role Mapping!", "info", 1500);
    }
  };

  const handleChangeRelevancy = (e, val, colKey, index, item) => {
    updateContentItemList(e, val, colKey, index);
  };

  const resetItemContainers = () => {
    selectedContentItemsContainer=[];
    selectedRelevancyItemContainer={};
  }
  const handleSelectedItems = (array, item) => {
    const indexToRemove = array.indexOf(item.contentId);
    if(array?.length < 50){
      const isItemSelected = array?.includes(item.contentId);
      if (!isItemSelected) {
        array.push(item.contentId);
        if(!showIrrelevantCourses){
          selectedRelevancyItemContainer[item.contentId] = item.relevancy;
        }
      } else {
        indexToRemove !== -1 && array.splice(indexToRemove, 1);
        if(!showIrrelevantCourses) {
          delete selectedRelevancyItemContainer[item.contentId];
        }
      }
    }else if(array?.length === 50 && array?.includes(item?.contentId)){
      indexToRemove !== -1 && array.splice(indexToRemove, 1);
    }
    else{
      setWarningPopUp(true);
    }
  }

  const handleCheckBox = (e, item) => {
      handleSelectedItems(selectedContentItemsContainer, item);
      const isSelected = selectedContentItemsContainer.includes(item.contentId);
      if(selectedContentItemsContainer?.length === 50 && !isSelected){
        return;
      }
      const arr = [...skillContentItems?.contentDetailList];
      const updatedArr = arr.map((el, i) =>
          el.contentId === item.contentId
              ? { ...el, checked: e.target.checked }
              : { ...el }
      );
      setSkillContentItems({
          ...skillContentItems,
          contentDetailList: updatedArr,
      });
  };

  const handleCheckAll = (e) => {
      const arr = [...skillContentItems?.contentDetailList];
      if (e.target.checked && arr.length > 0) {
        arr.forEach((element) => {
          if (selectedContentItemsContainer.length === 50) {
            setWarningPopUp(true);
          } else if (selectedContentItemsContainer.length < 50) {
            selectedContentItemsContainer.push(element.contentId);
            if(!showIrrelevantCourses){
              selectedRelevancyItemContainer[element.contentId] = element.relevancy;
            }
          }
        });
      } else {
        resetItemContainers();
      }
      const updatedArr = arr.map((el) => ({ ...el, checked: selectedContentItemsContainer?.includes(el?.contentId) || false }));
      setSkillContentItems({
          ...skillContentItems,
          contentDetailList: updatedArr,
      });
  };

  const updateContentItemList = (e, val, colKey, index) => {
    const arr = [...skillContentItems?.contentDetailList];
    const updatedArr = arr.map((el, i) =>
      index === i
        ? {
            ...el,
            [colKey]: e,
          }
        : {
            ...el,
          }
    );
    setSkillContentItems({
      ...skillContentItems,
      contentDetailList: updatedArr,
    });
  };

  const handleContentItemSave = async (e, item, i) => {
    e.stopPropagation();
    setContentLoading(true);
    try {
        await SkillService.bulkUpdateSkillContentItemV3(
          `/${id}`,
          [item.contentId],
          item.relevancy === "Yes"
        );
        getContentItems(rowCount, pageOffset, showIrrelevantCourses, false);
    } catch (error) {
      console.log(error);
    } finally {
      setCurrentContentItemRow(null);
      resetItemContainers();
    }
  };

  const handleUpdateContentRelevancy = async () => {
      setConfirmPopup(false);
      setContentLoading(true);
      try {
          if(!showIrrelevantCourses && isRelevant){
              var hasYesValue = Object.values(selectedRelevancyItemContainer).includes('Yes');
              var hasNotVerifiedValue = Object.values(selectedRelevancyItemContainer).includes('Not verified');
              if(hasYesValue && hasNotVerifiedValue){
                  var filteredContentIds = Object.keys(selectedRelevancyItemContainer).filter(contentId => {
                    return selectedRelevancyItemContainer[contentId] === 'Not verified';
                });
                selectedContentItemsContainer = filteredContentIds;
              }
          }
          await SkillService.bulkUpdateSkillContentItemV3(
              `/${id}`,
              selectedContentItemsContainer,
              isRelevant
          );
          clearSelectedContentItems();
          resetItemContainers();
          await getContentItems(rowCount, pageOffset, showIrrelevantCourses, false);
      } catch (error) {
          console.log(error);
      }
  };

  const clearSelectedContentItems = () => {
      const arr = [...skillContentItems?.contentDetailList];
      const updatedArr = arr.map((el) => ({ ...el, checked: false }));
      setSkillContentItems({
          ...skillContentItems,
          contentDetailList: updatedArr,
      });
  };

  const getStaticList = async () => {
    try {
      const resSubSectors = await AuthService.subSectorListV3();
      const sorted = SortString(resSubSectors, "subsector");
      setSubSectors(
        sorted.map((el) => ({
          label: el.subsector,
          value: el.subsectorId,
          type: el.subsectorTypeId,
        }))
      );

      const resLevels = await SkillService.getSkillProficiencyLevelsV3(skillSourceId);
      const levelMenu = await resLevels.map((el) => ({
        label: el.level,
        value: el.levelId,
        levelNumber: el.levelNumber,
      }));

      const sortLevels = SortString(levelMenu, "levelNumber") || [];

      setLevelMenu(sortLevels);
      modifyProficiency(sortLevels);
      modifyRoles(levelMenuRoles);

      const resPersonas = await SkillService.getSkillTypesArrayV3(Number(id), "personas");
      setSkillPersonas(resPersonas);
      setSkillPersonasCopy(resPersonas);

      getAssessmentList();
    } catch (err) {
      console.log(err);
    }
  };

  const getAssessmentList = async () => {
    const resAssessment = await SkillService.getSkillTypesArrayV3(Number(id), "assessments");
    const assessmentListMapped = resAssessment.map((el) => ({
      assessmentType: el.type.name,
      assessmentSubType: el.subType.name,
      evaluator: el?.evaluatorType?.name || "",
      ...el,
    }));
    const sortLevels = SortString(assessmentListMapped, "levelName") || [];
    setAssessmentGenerated(checkAllAssessment(sortLevels));
    setSkillAssessmentList(sortLevels);
    setSkillAssessmentListCopy(sortLevels);
  };
  const checkAllAssessment = (arr) => {
    const filterArray = arr.filter(
      (el) =>
        el.assessmentType === "Evaluation" &&
        el.assessmentSubType === "Rating(1-5)" &&
        !!el.assessmentText
    );
    const result = filterArray.filter(
      (el) =>
        (el.levelName === "Level 1" && el.evaluator === "Manager") ||
        (el.levelName === "Level 2" && el.evaluator === "Manager") ||
        (el.levelName === "Level 3" && el.evaluator === "Manager") ||
        (el.levelName === "Level 4" && el.evaluator === "Manager") ||
        (el.levelName === "Level 5" && el.evaluator === "Manager") ||
        (el.levelName === "Level 1" && el.evaluator === "Self") ||
        (el.levelName === "Level 2" && el.evaluator === "Self") ||
        (el.levelName === "Level 3" && el.evaluator === "Self") ||
        (el.levelName === "Level 4" && el.evaluator === "Self") ||
        (el.levelName === "Level 5" && el.evaluator === "Self") ||
        (el.levelName === "Level 1" && el.evaluator === "Reportee") ||
        (el.levelName === "Level 2" && el.evaluator === "Reportee") ||
        (el.levelName === "Level 3" && el.evaluator === "Reportee") ||
        (el.levelName === "Level 4" && el.evaluator === "Reportee") ||
        (el.levelName === "Level 5" && el.evaluator === "Reportee") ||
        (el.levelName === "Level 1" && el.evaluator === "Peer") ||
        (el.levelName === "Level 2" && el.evaluator === "Peer") ||
        (el.levelName === "Level 3" && el.evaluator === "Peer") ||
        (el.levelName === "Level 4" && el.evaluator === "Peer") ||
        (el.levelName === "Level 5" && el.evaluator === "Peer")
    );
    return result?.length === 20;
  };

  const modifyRoles = async (menu = []) => {
    try {
      const resRoles = await SkillService.getSkillTypesArrayV3(Number(id), "roles");

      const insertLevel = await resRoles?.map((role) => ({
        ...role,
        level: role.proficiencyLevel.level,
        levelId: role.proficiencyLevel.levelId,
        levelNumber: role.proficiencyLevel.levelNumber,
      }));

      setSkillRoles(insertLevel);
      setSkillRolesCopy(insertLevel);
      const roleTableCol = [...Skill_Role_Table];
      const resTable = roleTableCol.map((el) => {
        if (el.select === true && el.colKey === "level") {
          return { ...el, selectMenu: menu };
        } else {
          return { ...el };
        }
      });
      setRoleTableColumn(resTable);
    } catch (err) {
      console.log(err);
    }
  };

  const modifyProficiency = async (menu = []) => {
    try {
      const res = await SkillService.getSkillTypesArrayV3(Number(id), "proficiencies");
      const prevLevel = !!res
        ? SortString(
            res?.map((el) => ({
              label: el.level,
              value: el.levelId,
              levelNumber: el.levelNumber,
              needId: el.proficiencyId,
            })),
            "levelNumber"
          )
        : [];
      modifyRoles(prevLevel);
      setLevelMenuRoles(prevLevel);
      const lMenu = !!menu ? [...menu] : [];
      const filterLevelMenu = lMenu.filter(
        (o1) => !res.some((o2) => o1.value === o2.levelId)
      );
      const profTableCol = Skill_Update_Proficiencies_Table;
      const resTable = profTableCol.map((el) => {
        if (el.select === true) {
          return { ...el, selectMenu: filterLevelMenu };
        } else {
          return { ...el };
        }
      });
      setProficiencyTableColumn(resTable);

      const resProficiency = await SortString(res, "levelNumber").map(
        (el, i) => ({
          ...el,
        })
      );

      setProficiencyArray(resProficiency);
      getAssessmentList();
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if(!!skillSourceId){
        getStaticList();

      return () => {
        setProficiencyArray(null);
        setSkillRoles(null);
      };
    }
  }, [skillSourceId]);

  // Role Edit
  const handleRoleEdit = (e, item, index) => {
    e.stopPropagation();
    if (currenRoleRow === null) {
      const roleTabArr = [...roleTableColumn].map((el) =>
        !!el.select && !!el.selectMenu
          ? {
              ...el,
              selectMenu: SortString(
                [
                  {
                    label: item.level,
                    value: item.level,
                    levelNumber: item.levelNumber,
                    needId: item.proficiencyId,
                  },
                  ...el.selectMenu,
                ],
                "levelNumber"
              ).filter((elem) => elem.value !== item.levelId),
            }
          : el
      );
      setRoleTableColumn(roleTabArr);
      setCurrenRoleRow(index);
    } else {
      showAlert("Kindly save the existing Role Mapping!", "info", 1500);
    }
  };

  const handleRoleSelect = (e, val, colKey, index, item) => {
    updateRoleList(e, val, colKey, index);
    const getFunctionsList = async () => {
      try {
        const res = await AuthService.functionsListV3(
          e !== 0 ? `?subSectorIds=${e}` : ""
        );
        const sorted = SortString(
          res.filter((el) => !!el.functionName),
          "functionName"
        );
        setFunctionList(
          sorted.map((el) => ({ label: el.functionName, value: el.functionId }))
        );

        getOccupations(sorted);
      } catch (error) {
        console.log(error);
      }
    };

    const getOccupations = async (list) => {
      try {
        const res = await RoleServices.getOccupationsV3(`?subSectorIds=${e}`);
        const sorted = await res.map((el) => !!el && el.trim()).sort();
        setOccupationList(sorted.map((el) => ({ label: el, value: el })));

        const roleTableCol = [...roleTableColumn];
        const resTable = roleTableCol.map((el) => {
          if (el.select === true && el.colKey === "functionName") {
            return {
              ...el,
              selectMenu: list.map((el) => ({
                label: el.functionName,
                value: el.functionId,
              })),
              dynamic: true,
            };
          } else if (el.select === true && el.colKey === "occupation") {
            return {
              ...el,
              selectMenu: sorted.map((el) => ({ label: el, value: el })),
              dynamic: true,
            };
          } else if (el.select === true && el.colKey === "roleName") {
            return {
              ...el,
              selectMenu: [],
              dynamic: true,
            };
          } else {
            return { ...el };
          }
        });
        setRoleTableColumn(resTable);
      } catch (error) {
        console.log(error);
      }
    };

    const getIndustryList = async () => {
      const initData = {
        roleName: "",
        subsectorIds: [item.subsector],
        functionIds: [e],
        occupations: [],
        roleStatuses: []
      };
      try {
        const res = await RoleServices.searchRoleV3(initData);
        const filterRoles = res?.roles.filter(
          (role) =>
            !skillRoles.some((element) => element.roleId === role.roleId)
        );
        const roleTableCol = [...roleTableColumn];
        const resTable = roleTableCol.map((el) => {
          if (el.select === true && el.colKey === "roleName") {
            return {
              ...el,
              selectMenu: SortString(filterRoles, "roleName").map((el) => ({
                label: el.roleName,
                value: el.roleId,
              })),
              dynamic: true,
            };
          } else {
            return { ...el };
          }
        });
        setRoleTableColumn(resTable);
      } catch (error) {
        console.log(error);
      }
    };
    if (colKey === "subsector") {
      getFunctionsList();
    } else if (colKey === "functionName") {
      getIndustryList();
    }
  };

  const updateRoleList = (e, val, colKey, index, item) => {
    const arr = [...skillRoles];
    const updatedArr = arr.map(
      (el, i) =>
        index === i
          ? colKey === "level"
            ? {
                ...el,
                [colKey]: e,
                needId:
                  val?.selectMenu.find((el) => el.value === e)?.needId ||
                  item?.proficiencyId,
              }
            : { ...el, [colKey]: e }
          : { ...el }
    );
    setSkillRoles(updatedArr);
  };

  const handleRoleSave = async (e, item, i) => {
    e.stopPropagation();
    
    const { roleId, roleName, needId, proficiencyId, level } = item;
  
    if (!needId || roleName === "" || level === "") {
      showAlert("Industry Role and Proficiency Level selection is required!", "info", 1500);
      return;
    }
  
    const url = `/${(roleId === 0 ? roleName : roleId)}`  ;
    const subEntityUrl = "/proficiency/";
    const method = roleId === 0 ? "POST" : "PATCH";
    const postData = { proficiencyId: needId, skillCriticality: "" };
    const subEntityId = roleId === 0 ? "" : proficiencyId;
  
    try {
      await RoleServices.addUpdateRoleSkillV3(url, subEntityUrl, subEntityId, postData, method);
      updateReset();
    } catch (error) {
      showAlert(error.error, "error", 1500);
    }
  };

  const handleRoleDelete = async (item) => {
    try {
      await RoleServices.deleteRoleSkillV3(
        "/" + item.roleId,
        "/proficiency/",
        item?.proficiencyId
      );
      showAlert("Successfully deleted", "success", 1500);
      setSortAndFilterReset(true);
      modifyRoles([]);
    } catch (error) {
      showAlert(error.error, "error", 1500);
    }
  };

  // Proficiancy Edit
  const handleProficiencyEdit = (e, item, index) => {
    e.stopPropagation();

    if (!currentProRow) {
      const proTabArr = [...proficiencyTableColumn].map((el) =>
        !!el.select
          ? {
              ...el,
              selectMenu: SortString(
                [
                  {
                    label: item.level,
                    value: item.level,
                    levelNumber: item.levelNumber,
                  },
                  ...el.selectMenu,
                ],
                "levelNumber"
              ),
            }
          : el
      );
      setProficiencyTableColumn(proTabArr);
      setCurrentProRow(index);
    } else {
      showAlert("Kindly save the existing Proficiency Mapping!", "info", 1500);
    }
  };

  const updateReset = () => {
    setCurrentProRow(null);
    setCurrenRoleRow(null);
    setCurrentContentItemRow(null);
    if (currentTab === 0) {
      modifyProficiency(levelMenu); //Proficiencies
    } else if (currentTab === 2) {
      modifyRoles(levelMenuRoles); // Roles
    }
  };

  const handleProficiencyDelete = async (item) => {
    try {
      await SkillService.deleteSkillTypeItemV3(
        "/" + Number(id),
        "/proficiencies/",
        item.proficiencyId
      );
      showAlert("Successfully deleted", "success", 1500);
      modifyProficiency(levelMenu);
    } catch (error) {
      showAlert(error.error, "error", 1500);
    }
  };

  const handleColChange = (e, val, colKey, index) => {
    updateList(e, val, colKey, index);
  };

  const handleProSelect = (e, val, colKey, index) => {
    updateList(e, val, colKey, index);
  };

  const updateList = (e, val, colKey, index) => {
    const arr = [...proficiencyArray];
    const levelObject = val?.selectMenu?.find((el) => el.value === e) || {};
    const updatedArr = arr.map((el, i) => {
      if (index === i) {
        return {
          ...el,
          [colKey]: colKey !== "level" ? e.target.value : e,
          levelName: colKey !== "level" ? el?.levelName : levelObject?.label,
          levelNumber:
            colKey !== "level" ? el?.levelNumber : levelObject?.levelNumber,
          levelId: colKey !== "level" ? el?.levelId : levelObject?.value,
        };
      } else {
        return { ...el };
      }
    });
    setProficiencyArray(updatedArr);
  };

  const addOrUpdateProficiency = async (item, shouldUpdateEvaluationAssessmentText = false ) => {
    const { buttonDelete, buttonEdit, levelName, ...postData } = item;
    const addUpdateAPITrigger = async () => {
      if (!!item.proficiencyId) {
        try {
          await SkillService.updateProficiencyV3(
            "/" + Number(id),
            "/proficiencies/",
            Number(postData.proficiencyId),
            { ...postData, level: levelName, shouldUpdateEvaluationAssessmentText }
          );
          updateReset();
        } catch (error) {
          showAlert(error.error, "error", 1500);
        }
      } else {
        try {
          await SkillService.addProficiencyV3(
            "/" + Number(id),
            "/proficiencies/",
            { ...postData, level: levelName }
          );
          updateReset();
        } catch (error) {
          showAlert(error.error, "error", 1500);
        }
      }
    };
    if (!!postData.levelId) {
      await addUpdateAPITrigger();
    } else {
      showAlert("Proficiency Level selection is required!", "info", 1500);
    }
  };
  const handleProficiencySave = async (e, item) => {
    e.stopPropagation();
    if (!!item.proficiencyId){
      var res = await SkillService.hasEvaluationAssessment(Number(id), item.proficiencyId);
      if (res.value) {
        popUp.confirm(text.skill.evaluationQuestionSyncConfirmationText, "", async () => await addOrUpdateProficiency(item, true));
        return;  
      }
      
    }
    addOrUpdateProficiency(item);
  };

  const handleAddPro = () => {
    const arr = [...proficiencyArray];
    const pushData = {
      level: "",
      levelId: "",
      levelNumber: "",
      description: "",
      knowledge: "",
      ability: "",
    };

    if (
      proficiencyArray.length > 0 &&
      !!proficiencyArray[proficiencyArray.length - 1].proficiencyId
    ) {
      arr.push(pushData);
      setProficiencyArray(arr);
      setCurrentProRow(arr.length - 1);
    } else if (proficiencyArray.length === 0) {
      arr.push(pushData);
      setProficiencyArray(arr);
      setCurrentProRow(arr.length - 1);
    } else {
      showAlert("You must save last Proficiency", "info", 1500);
    }
  };

  const handleAddRole = () => {
    if (skillRoles.length > 0 && !!skillRoles[skillRoles.length - 1].roleId) {
      makeSkillRole();
    } else if (skillRoles.length === 0) {
      makeSkillRole();
    } else {
      showAlert(
        "Kindly save the existing Role Mapping before adding another!",
        "info",
        2500
      );
    }
  };

  const makeSkillRole = () => {
    const arr = [...skillRoles];

    const pushData = {
      subsector: "",
      functionName: "",
      occupation: "",
      roleName: "",
      roleId: 0,
      level: "",
      levelId: 0,
    };

    arr.push(pushData);
    setSkillRoles(arr);
    setCurrenRoleRow(arr.length - 1);

    const roleTableCol = [...Skill_Role_Table];
    const resTable = roleTableCol.map((el) => {
      if (el.select === true && el.colKey === "level") {
        return { ...el, selectMenu: levelMenuRoles };
      } else if (el.select === true && el.colKey === "subsector") {
        return { ...el, selectMenu: subSectors, dynamic: true };
      } else if (el.select === true && el.colKey === "functionName") {
        return { ...el, selectMenu: functionList, dynamic: true };
      } else if (el.select === true && el.colKey === "occupation") {
        return { ...el, selectMenu: occupationList, dynamic: true };
      } else if (el.select === true && el.colKey === "roleName") {
        return { ...el, selectMenu: [], dynamic: true };
      } else {
        return { ...el };
      }
    });
    setRoleTableColumn(resTable);

    const tableLastRow = document.querySelector(".lastRow");
    setTimeout(() => {
      tableLastRow.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }, 100);
  };

  const handleAiGenerator = (el) => {
    if(el.colValue === "Description"){
      setShowDescriptionLevelPopUp({proficiencyDescriptionLevel: true});
    }else if(el.colValue === "Knowledge"){
      setShowDescriptionLevelPopUp({knowledgeDescriptionLevel: true});
    }
  };

  const handleAiGeneratorNoClick = () => {
   setShowDescriptionLevelPopUp({proficiencyDescriptionLevel: false, knowledgeDescriptionLevel: false})
  }
  const addOrUpdateProficiencies = async (levelsToBeUpdated) => {
    try {
      setFullLoader(true);
      const validProficiencyLevels = levelsToBeUpdated.filter(Boolean);
      for(const item of validProficiencyLevels){
          item?.proficiencyId 
            ? await SkillService.updateProficiencyV3("/" + Number(id), "/proficiencies/", Number(item.proficiencyId), { ...item })
            : await SkillService.addProficiencyV3("/" + Number(id), "/proficiencies/", { ...item  });
      }
      updateReset();
      showAlert("Level descriptions updated successfully", "success", 1500);
      handleAiGeneratorNoClick();
    } catch (errors) {
      showAlert(errors, "error", 1500);
      updateReset();
      handleAiGeneratorNoClick();
      console.log("error", errors);
    } finally {
      setFullLoader(false);
    }
  };
  const handleAiGeneratorYesClick = async (levels) => {
    const updatedProficiencyArray = [...proficiencyArray];
    const levelsToBeUpdated = Object.keys(levels).map(async (level, i) => {
      const description = levels[level];
      let item = updatedProficiencyArray?.find((item) => item.level === level);
      let shouldUpdateEvaluationAssessmentText = false;
      if (item?.proficiencyId) {
        let res = await SkillService.hasEvaluationAssessment(Number(id), Number(item.proficiencyId));
        shouldUpdateEvaluationAssessmentText = res.value;
      }
      if(item && description){
        showDescriptionLevelPopUp.proficiencyDescriptionLevel  
             ? (item.description = item?.description ? item.description +  ' ' + description : description)
            : (item.knowledge = item?.knowledge ? item.knowledge + ' ' + description : description);
        item.shouldUpdateEvaluationAssessmentText = shouldUpdateEvaluationAssessmentText;
        
      }else if(description) {
        const levelInfo = levelMenu?.find((sourceLevel) => sourceLevel?.label === level);
        item = {
            level: levelInfo?.label ?? '',
            levelId: levelInfo?.value ?? '',
            levelNumber: levelInfo?.levelNumber ?? '',
            description: showDescriptionLevelPopUp.proficiencyDescriptionLevel ? description : "",
            knowledge: showDescriptionLevelPopUp.knowledgeDescriptionLevel ? description : "",
            ability: "",
            shouldUpdateEvaluationAssessmentText: shouldUpdateEvaluationAssessmentText
          };
      }
      return item;
    });
    
    Promise.all(levelsToBeUpdated).then(async (results) => {
      if (results.some(i => i?.shouldUpdateEvaluationAssessmentText)) {
        popUp.confirm(text.skill.evaluationQuestionSyncConfirmationText, "", async () => await addOrUpdateProficiencies(results));
        return;
      }
      await addOrUpdateProficiencies(results);
    });
  };  

  const updateLevelsDescriptions = (updatedDescriptionValues, updatedKnowledgeDescriptionValues) => {
    AIStore.update((s) => {
      showDescriptionLevelPopUp.proficiencyDescriptionLevel 
        ? s.aiSuggestedProficienyDescription = [...aiSuggestedProficienyDescription, updatedDescriptionValues]
        : s.aiSuggestedKnowledgeDescription = [...aiSuggestedKnowledgeDescription, updatedKnowledgeDescriptionValues];
    });
  }

  const getStoreKey = () => {
    if(showDescriptionLevelPopUp.proficiencyDescriptionLevel){
      return 'aiSuggestedProficienyDescription';
    }else if(showDescriptionLevelPopUp.knowledgeDescriptionLevel){
      return 'aiSuggestedKnowledgeDescription';
    }return ''
  }
  
  return (
    <>
      <SkillTableBlock>
        <TabCustom
          tabTitle={tabList}
          getTabNumber={handleTab}
          currentTab={currentTab}
        >
          <TabPanel
            value={currentTab}
            index={0}
            style={{ padding: 0 }}
            className="top-table"
          >
            {!!skillSourceId && skillSourceId === SourceType.DISPRZ_ATOMIC_SKILLS &&
              <div className="choose-proficiency-level">
              <H5>How many Proficiency levels do you want to configure?</H5>
               <div className="select-box">
                  <AutoSelectBox
                    stateValue={5}
                    disabled={true}
                  />
               </div>
            </div>}
            <CustomizedTables
              columns={proficiencyTableColumn}
              rowList={proficiencyArray}
              columnChange={handleColChange}
              // columnBlur={handleColBlur}
              currentRow={currentProRow}
              handleSelect={handleProSelect}
              editClick={handleProficiencyEdit}
              saveClick={handleProficiencySave}
              deleteClick={handleProficiencyDelete}
              cancelClick={() => updateReset()}
              stickyHeader={proficiencyArray?.length > 10 && currentTab === 0}
              tableHeight={
                proficiencyArray?.length > 10 && currentTab === 0
                  ? profiTableHeight
                  : "auto"
              }
              className="profi-table"
              innerTabel={true}
              deleteConfirm={true}
              deleteConfirmText="Confirm Delete Proficiency"
              handleAiGenerator={handleAiGenerator}
            />
            <div className="btn-block">
              <PrimaryButton onClick={handleAddPro}>Add Level</PrimaryButton>
            </div>
          </TabPanel>
          {User_Type !== 3 && (
            <>
              <TabPanel value={currentTab} index={1}>
              <div className={"control-header"}>
                  <FormControlLabel
                      control={
                          <Checkbox
                              checked={showIrrelevantCourses}
                              onChange={() => {
                                  setCurrentContentItemRow(null);
                                  setPageOffset(0);
                                  setShowIrrelevantCourses((prev) => !prev);
                                  resetItemContainers();
                              }}
                              name="showIrrelevantCourses"
                              color="primary"
                          />
                      }
                      label="Show learning content marked as irrelevant"
                  />
                  <div className="relevancy-btn">
                    {handleRelevancyButton("relevant")}
                    {!showIrrelevantCourses && handleRelevancyButton("irrelevant")}
                  </div>
              </div>
                <ModalPopup
                    open={confirmPopup}
                    maxWidth="sm"
                    title={`Do you want to mark all the selected content as ${isRelevant ? 'relevant' : 'irrelevant'}`}
                    center={true}
                >
                  <ConfirmationButton yesClick={handleUpdateContentRelevancy} noClick={() => { setConfirmPopup(false); }} />
                </ModalPopup>
                  <div className={"content-table"}>
                      {
                          contentLoading ? <Backdrop>
                              <DotLoader />
                          </Backdrop> : ""
                      }
                      <CustomizedTables
                          columns={Skill_ContentItems_Table_Relevancy}
                          rowList={skillContentItems?.contentDetailList}
                          rowListCopy={skillContentItemsCopy?.contentDetailList}
                          innerTabel={true}
                          currentRow={currentContentItemRow}
                          handleSelect={handleChangeRelevancy}
                          handleCheckBox={handleCheckBox}
                          handleCheckAll={handleCheckAll}
                          editClick={handleContentItemEdit}
                          saveClick={handleContentItemSave}
                          cancelClick={() => {
                              setSkillContentItems(skillContentItemsCopy);
                              setCurrentContentItemRow(null);
                              resetItemContainers();
                          }}
                          resetRow={() => setCurrentContentItemRow(null)}
                          saveAndCancel={true}
                          sortAndFilterAPILevel={{ sort: true, filter: true }}
                          handleSortTable={(value) => setContentSort(value)}
                          filterList={skillContentItems?.filters || []}
                          getFilterValues={(value) => setContentFilters(value)}
                      />
                  </div>
                {skillContentItems?.total > 10 && !contentLoading ? (
                  <PaginationCustom
                    pageTotal={skillContentItems?.total}
                    handlePagination={handlePagination}
                    countRows={rowCount}
                    changeRowCount={changeRowCount}
                  />
                ) : null}
                {skillContentItems?.total === 0 && !contentLoading ? (
                  <H5
                    style={{
                      padding: 20,
                      textAlign: "center",
                    }}
                  >
                    {!contentFilters?.length
                      ? "No Learning Content is mapped to this skill"
                      : "Search results are not found"}
                  </H5>
                ) : null}
              </TabPanel>
              <TabPanel value={currentTab} index={2}>
                <RowCount
                  text="Roles mapped: "
                  count={skillRolesCopy?.length}
                />
                <CustomizedTables
                  columns={roleTableColumn}
                  rowList={skillRoles}
                  rowListCopy={skillRolesCopy}
                  currentRow={currenRoleRow}
                  handleSelect={handleRoleSelect}
                  editClick={handleRoleEdit}
                  saveClick={handleRoleSave}
                  deleteClick={handleRoleDelete}
                  cancelClick={() => updateReset()}
                  stickyHeader={skillRoles?.length > 10}
                  tableHeight={
                    skillRoles?.length > 10 && currentTab === 2
                      ? roletableHeight
                      : "auto"
                  }
                  innerTabel={true}
                  deleteConfirm={true}
                  deleteConfirmText="Confirm Delete Role"
                  sortList={(sortValue, sortBy) => {
                    setCurrenRoleRow(null);
                    setSkillRoles(SortString(skillRoles, sortValue, sortBy));
                  }}
                  filterChange={(item, reason, colKey, list) =>
                    FilterTableRow(item, reason, colKey, list, setSkillRoles)
                  }
                  resetList={() => {
                    setCurrenRoleRow(null);
                    setSkillRoles(skillRolesCopy);
                  }}
                  resetRow={() => setCurrenRoleRow(null)}
                />
                <div className="btn-block">
                  <PrimaryButton onClick={handleAddRole}>
                    Add Role
                  </PrimaryButton>
                </div>
              </TabPanel>
              <TabPanel value={currentTab} index={3}>
                <RowCount
                  text="Personas mapped: "
                  count={skillPersonasCopy?.length}
                />
                <CustomizedTables
                  columns={Skill_Persona_Table}
                  rowList={skillPersonas}
                  innerTabel={true}
                  rowListCopy={skillPersonasCopy}
                  sortList={(sortValue, sortBy) =>
                    setSkillPersonas(
                      SortString(skillPersonas, sortValue, sortBy)
                    )
                  }
                  filterChange={(item, reason, colKey, list) =>
                    FilterTableRow(item, reason, colKey, list, setSkillPersonas)
                  }
                  resetList={() => setSkillPersonas(skillPersonasCopy)}
                />
              </TabPanel>
              <TabPanel value={currentTab} index={4}>
                {!!skillAssessmentList && (
                  <SkillAssessment
                    list={skillAssessmentList || []}
                    listCopy={skillAssessmentListCopy}
                    setList={setSkillAssessmentList}
                    levelMenu={levelMenuRoles || []}
                    skillId={id}
                    getAssessmentList={getAssessmentList}
                    proficiencyArray={proficiencyArray}
                    detailData={detailData}
                    assessmentGenerated={assessmentGenerated}
                  />
                )}
              </TabPanel>
            </>
          )}
        </TabCustom>
      </SkillTableBlock>
      <ModalPopup
        open={showDescriptionLevelPopUp.proficiencyDescriptionLevel || showDescriptionLevelPopUp.knowledgeDescriptionLevel}
        maxWidth="lg"
        title={showDescriptionLevelPopUp.proficiencyDescriptionLevel ? "Proficiency Description" : "Knowledge Description"}
        center={true}
      >
        <AIGenerator
          generatorType={AIGeneratorType.LEVEL}
          getServiceUrl={SkillService.GetAiSkillLevelDescriptionSuggestionV3}
          params={skillDetail}
          cancelText="Close"
          confirmText="Add to Main Screen"
          onCancel={() => handleAiGeneratorNoClick()}
          onConfirm={(value) =>  handleAiGeneratorYesClick(value)}
          placeholder=""
          knowledgeDescriptionLevel={showDescriptionLevelPopUp.knowledgeDescriptionLevel}
          levels={5}
          aiStoreKey={getStoreKey()}
          updateLevelsDescriptions={(updatedDescriptionValues, updatedKnowledgeDescriptionValues) => updateLevelsDescriptions(updatedDescriptionValues, updatedKnowledgeDescriptionValues)}
        />
      </ModalPopup>
      <ModalPopup
        open={contentDetailModal}
        maxWidth="md"
        closePopup={() => {
          setContentDetailModal(false);
        }}
        title="Details"
      >
        <SkillContentDetail skillId={id} />
      </ModalPopup>
      <ModalPopup
        open={warningPopUp}
        maxWidth="sm"
        title={"Please select a maximum of 50 items at a time"}
        center={true}
        className="warning-modal"
      >
       <ConfirmBlock>
          <PrimaryButton onClick={()=>setWarningPopUp(false)}>
           Ok
          </PrimaryButton>
       </ConfirmBlock>
      </ModalPopup>
    </>
  );
};

const ConfirmBlock = styled.div`
  text-align: center;`;


const SkillTableBlock = styled.div`
  background-color: ${COLORS.PRIMARY_WHITE};
  margin-bottom: 30px;
  width: calc(100vw - 245px);
  .choose-proficiency-level{
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 25px;
    h5{
      padding: 0px !important;
    }
    .select-box{
      .MuiAutocomplete-root{
        width: 150px !important;
          .MuiFormControl-root{
          border-radius: 6px;
          border: 0.7px solid #A6B8D8;
          .MuiAutocomplete-inputRoot{
            padding: 2px !important;
            .MuiOutlinedInput-input {
              color: unset;
            }
          }
        }
      }
    }
  }
  &:last-child {
    margin-bottom: 0px;
  }
  .MuiBox-root {
    padding: 0px;
  }
  table {
    padding: 0px 10px;
  }
  .top-table .MuiTableCell-body {
    vertical-align: top;
  }
  .MuiInputBase-root {
    margin: 0;
  }
  .MuiFormControl-root {
    padding-top: 0;
  }
  .MuiSelect-selectMenu {
    padding: 13px 26px 14px 12px;
  }
  .btn-block {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 20px;
  }
  .control-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .content-table {
    .loader {
        position: fixed;
        top: 40%;
    }
  }
  .relevancy-btn{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    min-height: 10px;
  }
  .more_action{
      border: 1px solid #b0b4d0;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 5px;
      min-height: 10px;
      padding: 2px 10px;
      &:hover {
          cursor: pointer;
          background-color: #f0f3f8;
          border: 1px solid #b0b4d0;
      }
  }
  .more-option-container {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-bottom: 12px;
  }
`;

const Backdrop = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.6);
  z-index: 9999999;
  top: 0px;
  left: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
`;