import React, { useState, useEffect } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";

import { capitalize, FormControlLabel } from "@material-ui/core";
import { PrimaryButton } from "../../Common/Buttons/PrimaryButton";
import CustomizedTables from "../../Common/CustomizedTables";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import DropDown from "../../Common/FormElements/DropDown";
import Checkbox from "@material-ui/core/Checkbox";
import ModalPopup from "../../Common/ModalPopup";
import { skillAssessmentAddValidation } from "../../../validations/personaValidation";
import styled from "styled-components";
import FormTextField from "../../Common/FormElements/FormTextField";
import { SkillService } from "../../../services/SkillService";
import useFullLoader from "../../../Hooks/useFullLoader";
import PopHover from "../../Common/PopModal/PopHover";
import { SortString } from "../../../helpers/SortString";
import { SecondaryButton } from "../../Common/Buttons/SecondaryButton";
import useAuth from "../../../Hooks/useAuth";
import useAlert from "../../../Hooks/useAlert";
import {
  FilterTableRow,
  FilterTableRowByTextValue,
} from "../../../helpers/FilterTableRow";
import { useTableContext } from "../../../Provider/TableProvider";
import ToolBar from "../../Common/ToolBar";
import ConfirmationButton from "../../Common/ConfirmationButton";
import { AssessmentType } from "../../../enums/AssessmentType";
import { AssessmentSubType } from "../../../enums/AssessmentSubType";
import { SourceType } from "../../../enums/SourceType";
import { isAssessmentAllowed } from "../../../helpers/CommonHelper";
import { skillStatusType } from "../../../enums/SkillStatusType";
import usePopUp  from '../../../Hooks/usePopUp';
import text from '../../../locale.en.json';

function SkillAssessment(props) {
  const {
    list,
    listCopy,
    setList,
    getAssessmentList,
    skillId,
    proficiencyArray,
    detailData,
    assessmentGenerated,
    readOnly
  } = props;
  const { setFullLoader } = useFullLoader();
  const { showAlert } = useAlert();
  const { setSortAndFilterReset } = useTableContext();
  const [detailForm, setDetailForm] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [confirmPopup, setConfirmPopup] = useState(false);
  const [exportConfirmPopup, setExportConfirmPopup] = useState(false);
  const { popUp } = usePopUp();

  const handleAddAssessment = () => {
    setDetailForm({ open: true, data: null, mode: "add" });
  };

  const handleAssessmentEdit = (e, item, index) => {
    e.stopPropagation();
    setDetailForm({ open: true, data: item, mode: readOnly ? "view" : "edit" });
  };

  const handleMoreOpen = (e) => {
      e.stopPropagation();
      setAnchorEl(e.currentTarget);
  };

  const handleMoreClose = () => {
      setAnchorEl(null);
  };

  const isDisprzAtomicSkillSource = () => {
    return detailData.skillSource.sourceId === SourceType.DISPRZ_ATOMIC_SKILLS;
  };

  const isAnyAdaptiveTypeQuestionDefined = () => {
    return list.some(l => l.type.assessmentTypeId === AssessmentType.ADAPTIVE);
  };

  const getExportToolbarTitle = () => {
      let exportToolbarTitle = "";
      if (!checkAllProficiency()) {
          exportToolbarTitle = "Kindly define all 5 proficiencies for the Skill";
      }
      else if (!isAnyAdaptiveTypeQuestionDefined()) {
          exportToolbarTitle = "Kindly define Adaptive Assessment Questions";
      }
      else {
          // do nothing
      }
      return exportToolbarTitle;
  };

  const renderAutoGenerate = () => {
    let disable = !checkAllProficiency() || assessmentGenerated;
    let label = text.skill.autoGenerateEvaluationQuestions;
    let tooltip = text.skill.defineAllSP;
    if(assessmentGenerated)
      tooltip = text.skill.assessmentsDefinedAndPublished;
    let triggerPublishAPI = false;
    const statusesToExclude = [skillStatusType.PUBLISHED, skillStatusType.RETIRED];
    let hasUnPublishedEvaluations = list.some(item => item.type.assessmentTypeId == AssessmentType.EVALUATION && !statusesToExclude.includes(item.status))
    if (assessmentGenerated && hasUnPublishedEvaluations) {
      label = text.skill.markAllEvaluationQuestionsPublished;
      tooltip = label;
      triggerPublishAPI = true;
      disable = false;
    }
    
    return !!detailData?.skillSource?.isAssessmentEnabled ? (
        <div className={"auto-generate"}>
          <ToolBar
              title={tooltip}
              arrow={true}
              darkMode>
            <div>
              <SecondaryButton
                  onClick={()=> handleAutoGenerate(triggerPublishAPI)}
                  disabled={disable}>
                {label}
              </SecondaryButton>
            </div>
          </ToolBar>
        </div>
      ) : null;
  };

  const MoreActionContent = () => (
    <MoreContent>
      <div className={"more-content"}>
        {renderAutoGenerate()}
        <div className={"export-container"}>
          <ToolBar
              title={getExportToolbarTitle()}
              arrow={true}
              darkMode>
              <div>
                <SecondaryButton
                    onClick={handleExport}
                    disabled={!checkAllProficiency() || !isAnyAdaptiveTypeQuestionDefined()}>
                  Export Questions to Adaptive Engine
                </SecondaryButton>
              </div>
          </ToolBar>
        </div>
      </div>
    </MoreContent>
  );

  const handleAssessmentDelete = async (item) => {
    setFullLoader(true);

    const {
      levelId,
      assessmentOptions,
      assessmentLanguage,
      assessmentText,
      assessmentTestId,
      assessmentUrl,
      type,
      subType,
      evaluatorType,
    } = item;
    try {
      const postData = {
        status: 5, // 5 === retired
        levelId,
        assessmentOptions,
        assessmentLanguage,
        assessmentText,
        assessmentTestId,
        assessmentUrl,
        assessmentTypeId: type.assessmentTypeId,
        assessmentSubTypeId:
          (type.assessmentTypeId === AssessmentType.CODERBYTE || isAssessmentAllowed(type.assessmentTypeId)  || type.assessmentTypeId === AssessmentType.ADAPTIVE)
            ? type.assessmentTypeId
            : subType.assessmentSubTypeId,
        assessmentEvaluatorTypeId: evaluatorType.evaluatorTypeId,
      };
      await SkillService.EditAssessmentV3(skillId, postData, item.assessmentId);
      showAlert("Successfully deleted", "success", 1500);
      setSortAndFilterReset(true);
      getAssessmentList();
    } catch (error) {
      // console.log(error);
      showAlert(error.error, "error", 1500);
    } finally {
      setFullLoader(false);
      const tableBottomRow = document.querySelector(".btn-block");

      tableBottomRow.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
  };

  const resetForm = () => setDetailForm({ open: false, data: null, mode: "" });

  const handleSave = () => {
    getAssessmentList();
    resetForm();
  };

  const checkAllProficiency = () => {
    const result = proficiencyArray.filter(
      (el) =>
        (el.level === "Level 1" && !!el.description) ||
        (el.level === "Level 2" && !!el.description) ||
        (el.level === "Level 3" && !!el.description) ||
        (el.level === "Level 4" && !!el.description) ||
        (el.level === "Level 5" && !!el.description)
    );
    return result?.length === 5;
  };

  const handleAutoGenerate = (triggerEvaluationPublishAPI) => {
    setAnchorEl(null);
    if (triggerEvaluationPublishAPI)
      popUp.confirm("", text.skill.markAllEvaluationQuestionsPublished, () => handlePublishEvaluations());
    else
      setConfirmPopup(true);
  };

  const handleExport = () => {
    setAnchorEl(null);
    setExportConfirmPopup(true);
  };
  const handlePublishEvaluations = async () => {
      setFullLoader(true);
      await SkillService.publishEvaluations(skillId);
      setFullLoader(false);
      await showAlert(
        "All evaluation questions published the skill",
        "success",
        1500
      );
      handleSave();
  };
  const handleYesAutoGenerate = async () => {
    setConfirmPopup(false);
    setFullLoader(true);
    try {
      await showAlert(
        "Auto-generation of assessment questions in progress",
        "info",
        1500
      );
      await SkillService.GenerateAssessmentV3(skillId);
      await showAlert(
        "Assessment questions generated for the skill",
        "success",
        1500
      );
      handleSave();
    } catch (error) {
      showAlert(error.error, "error", 1500);
    } finally {
      setFullLoader(false);
    }
  };

  const handleNoAutoGenerate = () => {
    setConfirmPopup(false);
  };

  const handleNoExport = () => {
    setExportConfirmPopup(false);
  };

  const handleYesExport = async () => {
    setExportConfirmPopup(false);
    setFullLoader(true);
    try {
        await SkillService.ExportAdaptiveAssessmentQuestions(skillId);
        showAlert("Questions exported to Adaptive Engine", "success", 2000);
    } catch (errors) {
        showAlert(errors.error, "error", 5000);
    } finally {
        setFullLoader(false);
    }
  };

  const ConfirmModal = () => (
    <ModalPopup
      open={confirmPopup}
      maxWidth="sm"
      title={
        "Do you want to auto-generate 360 degree assessments for this skill?"
      }
      center={true}
    >
      <p style={{ textAlign: "center", paddingBottom: 10 }}>
        <u>Note:</u> Please ensure all proficiency level descriptions are
        properly defined for the skill
      </p>
      <ConfirmationButton
        yesClick={handleYesAutoGenerate}
        noClick={handleNoAutoGenerate}
      />
    </ModalPopup>
  );
  

  const ExportConfirmModal = () => (
    <ModalPopup
      open={exportConfirmPopup}
      maxWidth="sm"
      title={
        "DO YOU WANT TO EXPORT QUESTIONS TO ADAPTIVE ENGINE?"
      }
      center={true}
    >
      <p style={{ textAlign: "center", paddingBottom: 10 }}>
        <u>Note:</u> Please check for the following before proceeding with the Export -
      </p>
      <ol style={{ listStyle: "auto", paddingLeft: 20}}>
        <li>{'Ensure min of 2 questions per proficiency level per skill'}</li>
        <li>{'Ensure that the questions are free from any typos/grammatical mistakes'}</li>
        <li>{'All questions for each proficiency level with Assessment Type as "Adaptive" would be exported'}</li>
      </ol>
      <ConfirmationButton
        yesClick={handleYesExport}
        noClick={handleNoExport}
      />
    </ModalPopup>
  );

  return (
    <div>
      {!readOnly ? isDisprzAtomicSkillSource() ? <>
        <div className={"more-option-container"}>
          <div className="more_action" onClick={(e) => handleMoreOpen(e)}>
              {`More Option${detailData?.skillSource?.isAssessmentEnabled ? 's' : ''}`} <ArrowDropDownIcon color="action" />
          </div>
        </div>
        <PopHover anchorEl={anchorEl} handleClose={handleMoreClose}>
          <MoreActionContent />
        </PopHover>
      </> : renderAutoGenerate() : null}
      <CustomizedTables
        columns={readOnly ? Skill_Assessment_ReadOnly_Table : Skill_Assessment_Table}
        rowList={list}
        innerTabel={true}
        editClick={handleAssessmentEdit}
        deleteClick={handleAssessmentDelete}
        deleteConfirm={true}
        deleteConfirmText="Confirm Delete Assessment"
        rowListCopy={listCopy}
        sortList={(sortValue, sortBy) =>
          setList(SortString(list, sortValue, sortBy))
        }
        filterChange={(item, reason, colKey, list) =>
          FilterTableRow(item, reason, colKey, list, setList)
        }
        filterChangeText={(value, colKey, list) =>
          FilterTableRowByTextValue(value, colKey, list, setList)
        }
        resetList={() => setList(listCopy)}
      />

      {!readOnly && 
        <div className="btn-block">
          <PrimaryButton onClick={handleAddAssessment}>
            Add Assessment
          </PrimaryButton>
        </div> }
        <ConfirmModal />
        <ExportConfirmModal />
      {!!detailForm && (
        <ModalPopup
          open={detailForm?.open || false}
          maxWidth="md"
          closePopup={resetForm}
          title={`${capitalize(detailForm.mode) || "Add"} Assessment`}
          titleStyle={{padding: '15px'}}
        >
          <AssessmentForm
            {...props}
            detailForm={detailForm}
            list={list}
            saveForm={handleSave}
            resetForm={resetForm}
          />
        </ModalPopup>
      )}
    </div>
  );
}

export const AssessmentForm = (props) => {
  const { levelMenu, skillId, detailForm, saveForm, resetForm, list } = props;
  const { setFullLoader } = useFullLoader();
  const { skillstatusMenuList } = useAuth();
  // console.log("AssessmentForm -> skillstatusMenuList", skillstatusMenuList);
  const { showAlert } = useAlert();

  const isEdit = () => detailForm.mode === "edit";
  const isReadOnly = () => detailForm.mode === "view";
  const showAssessmentDetails = isEdit() || isReadOnly();

  const initialAssessmentOptions = [
      {
          "option": "",
          "isCorrect": false
      },{
          "option": "",
          "isCorrect": false
      },{
          "option": "",
          "isCorrect": false
      },{
          "option": "",
          "isCorrect": false
      },{
          "option": "",
          "isCorrect": false
      }
  ];

  const isAdaptiveTypeQuestionAlreadyDefined = (currentQuestion) => {
      return list?.some(l => l.type.assessmentTypeId === AssessmentType.ADAPTIVE && l.assessmentText === currentQuestion);
  };

  const initData = {
    assessmentTypeId: showAssessmentDetails ? detailForm?.data.type.assessmentTypeId : 0,
    assessmentSubTypeId: showAssessmentDetails
      ? detailForm?.data.subType.assessmentSubTypeId
      : 0,
    assessmentEvaluatorTypeId: showAssessmentDetails
      ? detailForm?.data.evaluatorType.evaluatorTypeId
      : 0,
    levelId: showAssessmentDetails
      ? levelMenu?.find((el) => el.levelNumber === detailForm?.data.levelId)
          ?.value
      : 0,
    assessmentUrl: showAssessmentDetails ? detailForm?.data.assessmentUrl : "http://",
    assessmentTestId: showAssessmentDetails ? detailForm?.data.assessmentTestId : "",
    assessmentText: showAssessmentDetails ? detailForm?.data.assessmentText : "",

    status: showAssessmentDetails ? detailForm?.data.status : skillstatusMenuList[0]?.value,
    assessmentOptions: showAssessmentDetails ? detailForm?.data.assessmentOptions : initialAssessmentOptions,
    isCorrectErrorMessage: "",
    isQuestionAlreadyDefinedErrorMessage: false,
    assessmentLanguage: "en",
  };

  const [staticList, setStaticList] = useState(null);

  useEffect(() => {
    getStaticList();
  }, []);

  const getStaticList = async () => {
    setFullLoader(true);
    try {
      const promiseAllSettled = Promise.all([
        SkillService.GetAssessmentsTypeV3(`types`),
        // SkillService.GetAssessmentsTypeV3(`sub-types`),
        SkillService.GetAssessmentsTypeV3(`evaluator-types`),
      ]);
      promiseAllSettled.then((data) => setStaticList(data));
    } catch (err) {
      console.log(err);
    } finally {
      setFullLoader(false);
    }
  };

  // console.log(levelMenu);

  const formSubmit = async (values) => {
    //console.log("formSubmit -> values", values);
    const {
      assessmentTestId,
      assessmentUrl,
      assessmentText,
      assessmentOptions,
      assessmentTypeId,
      assessmentSubTypeId,
      assessmentEvaluatorTypeId,
      levelId,
      ...formValues
    } = values;

    setFullLoader(true);
    try {
      const postData = {
        ...formValues,
        levelId: levelMenu.find((el) => el.value === levelId).levelNumber || 0,
        assessmentTestId: isAssessmentAllowed(assessmentTypeId)  ? assessmentTestId : "",
        assessmentTypeId: assessmentTypeId,
        assessmentSubTypeId:
          (assessmentTypeId === AssessmentType.CODERBYTE || isAssessmentAllowed(assessmentTypeId) || assessmentTypeId === AssessmentType.ADAPTIVE)
            ? assessmentTypeId
            : assessmentSubTypeId,
        assessmentEvaluatorTypeId:
          (assessmentTypeId === AssessmentType.CODERBYTE || isAssessmentAllowed(assessmentTypeId) || assessmentTypeId === AssessmentType.ADAPTIVE)
            ? 1 //{evaluatorTypeId: 1, name: 'Self'}
            : assessmentEvaluatorTypeId,
        assessmentUrl: assessmentTypeId === AssessmentType.CODERBYTE ? assessmentUrl : "",
        assessmentText:
            (assessmentTypeId === AssessmentType.EVALUATION &&
          (assessmentSubTypeId === AssessmentSubType.RATING_1To5 || assessmentSubTypeId === AssessmentSubType.RATING_1To10)) || assessmentTypeId === AssessmentType.ADAPTIVE
            ? assessmentText
            : "",
        assessmentOptions: assessmentTypeId === AssessmentType.ADAPTIVE ? assessmentOptions : []
      };
      //console.log("formSubmit -> postData", postData);

      if (isEdit()) {
        await SkillService.EditAssessmentV3(
          skillId,
          postData,
          detailForm.data.assessmentId
        );
        showAlert("Assessment Updated", "success", 1500);
        saveForm();
      } else {
        await SkillService.AddAssessmentV3(skillId, postData);
        showAlert("Assessment Added", "success", 1500);
        saveForm();
      }
    } catch (error) {
      // console.log("formSubmit -> error", error);
      showAlert(error.error, "error", 1500);
    } finally {
      setFullLoader(false);
    }
  };

  const handleCancel = () => resetForm();

  const handleChange = (e, setFieldValue, values) => {
    //console.log(e.target.value);
    const { assessmentTypeId, assessmentSubTypeId } = values;
    const { name, value } = e.target;
    if (name === "assessmentTypeId") {
      if (e.target.value === AssessmentType.EVALUATION && assessmentSubTypeId === AssessmentSubType.LIKERT) {
        showAlert(
          "Temporarily, cannot add a LIKERT scale question from UI,<br /> please reach out to system admin for the same",
          "info",
          5000
        );
      } else if (e.target.value === AssessmentType.EVALUATION && assessmentSubTypeId === AssessmentSubType.GUIDEDSCALE) {
        showAlert(
          "Temporarily, cannot add a Guided scale question from UI,<br /> please reach out to system admin for the same",
          "info",
          5000
        );
      } else {
        setFieldValue(name, value);
      }
    } else if (name === "assessmentSubTypeId") {
      if (e.target.value === AssessmentSubType.LIKERT && assessmentTypeId === AssessmentType.EVALUATION) {
        showAlert(
          "Temporarily, cannot add a LIKERT scale question from UI,<br /> please reach out to system admin for the same",
          "info",
          5000
        );
      } else if (e.target.value === AssessmentSubType.GUIDEDSCALE && assessmentTypeId === AssessmentType.EVALUATION) {
        showAlert(
          "Temporarily, cannot add a Guided scale question from UI,<br /> please reach out to system admin for the same",
          "info",
          5000
        );
      } else {
        setFieldValue(name, value);
      }
    } else {
        setFieldValue(name, e.target.checked);
    }
  };

  const renderOptions = (setFieldValue, values) => {
    const toRender = [];
    initialAssessmentOptions.forEach((opt, index) => {
      toRender.push(<div className={`option ${index % 2 === 0 ? 'left' : 'right'}`}>
        <div className={"is-correct"}>
          <FormControlLabel
              control={<Checkbox checked={values?.assessmentOptions[index].isCorrect} disabled={isReadOnly()}/>}
              name={`assessmentOptions[${index}].isCorrect`}
              onChange={(e) => handleChange(e, setFieldValue, values)}
              label="Mark Correct"
          />
        </div>
        <FormTextField
            label={`Option${index+1}:`}
            name={`assessmentOptions[${index}].option`}
            isRequired
            disabled={isReadOnly()}
        />
      </div>)
    });
    return toRender;
  };
  // console.log(staticList);
  // [{"assessmentTypeId":1,"name":"Coderbyte"},{"assessmentTypeId":2,"name":"Evaluation"},{"assessmentTypeId":3,"name":"HackerEarth"},{"assessmentTypeId":4,"name":"Adaptive"}]
  // [{"assessmentSubTypeId":5,"name":"Rating(1-5)"},{"assessmentSubTypeId":6,"name":"Rating(1-10)"},{"assessmentSubTypeId":7,"name":"Guided Scale"},{"assessmentSubTypeId":8,"name":"Likert"}]
  return (
    <Formik
      enableReinitialize={true}
      initialValues={initData}
      validationSchema={skillAssessmentAddValidation}
      onSubmit={formSubmit}
      // innerRef={formRef}
    >
      {({ setFieldValue, setValue, setFieldTouched, values, errors }) => {
        return (
          <AssessmentFormWrapper>
            <div>
              <AssessmentFormBlock>
                <DropDown
                    list={
                        !!staticList
                            ? SortString(staticList[0], "type")?.map(
                        ({ typeId, type }) => ({
                        label: type,
                        value: typeId,
                    })
                        )
                        : []
                    }
                    name="assessmentTypeId"
                    width="190px"
                    label="Assessment Type"
                    handleChange={(e) => handleChange(e, setFieldValue, values)}
                    disabled={showAssessmentDetails}
                    isRequired
                />
                  {values?.assessmentTypeId === AssessmentType?.EVALUATION && (
                      <>
                      <DropDown
                      list={
                      !!staticList
                          ? SortString(
                          staticList[0].find(
                              (el) => el.typeId === values.assessmentTypeId
                          ).children || [],
                          "type"
                      )?.map(({ typeId, type }) => ({
                      label: type,
                      value: typeId,
                  }))
                      : []
                  }
                      name="assessmentSubTypeId"
                      width="190px"
                      label="Assessment Sub Type"
                      handleChange={(e) => handleChange(e, setFieldValue, values)}
                      disabled={showAssessmentDetails || values?.assessmentTypeId === AssessmentType?.UNDEFINED}
                      isRequired
                      />
                      <DropDown
                      list={
                      !!staticList
                          ? SortString(staticList[1], "name")?.map(
                      ({ evaluatorTypeId, name }) => ({
                      label: name,
                      value: evaluatorTypeId,
                  })
                      )
                      : []
                  }
                      name="assessmentEvaluatorTypeId"
                      width="190px"
                      label="Evaluator"
                      disabled={showAssessmentDetails}
                      isRequired
                      />
                      </>
                      )}

                <DropDown
                    list={skillstatusMenuList || []}
                    name="status"
                    width="190px"
                    label="Status"
                    disabled={isReadOnly()}
                />
                <DropDown
                    list={levelMenu || []}
                    name="levelId"
                    width="190px"
                    label="Proficiency Level"
                    disabled={showAssessmentDetails}
                    isRequired
                />
              </AssessmentFormBlock>

                {(isAssessmentAllowed(values.assessmentTypeId) || values.assessmentTypeId === AssessmentType.CODERBYTE) && <AssessmentFormBlock>
                    {isAssessmentAllowed(values.assessmentTypeId) && (
                        <FormTextField
                        label="Assessment Test ID:"
                        name="assessmentTestId"
                        isRequired
                        />
                        )}
                    {values.assessmentTypeId === AssessmentType?.CODERBYTE && (
                        <FormTextField
                        label="Assessment URL:"
                        name="assessmentUrl"
                        onChange={(e) => {
                        const prefix = "http://";
                        const input = e.target.value;
                            setFieldValue(
                                "assessmentUrl",
                                prefix + input.substr(prefix.length)
                            );
                        }}
                        isRequired
                        />
                        )}
                    </AssessmentFormBlock>}
                {(values.assessmentTypeId === AssessmentType?.EVALUATION &&
                (values.assessmentSubTypeId === AssessmentSubType?.RATING_1To5 ||
                values.assessmentSubTypeId === AssessmentSubType?.RATING_1To10)) || values.assessmentTypeId === AssessmentType?.ADAPTIVE ? (
                    <>
                    <FormTextField
                    label="Assessment Question:"
                    name="assessmentText"
                    multiline={true}
                    disabled={isReadOnly()}
                    rows="3"
                    rowsMax="1000"
                    onChange={(e) => {
                    const value = e.target.value;
                        setFieldValue("isQuestionAlreadyDefinedErrorMessage", isAdaptiveTypeQuestionAlreadyDefined(value));
                        setFieldValue("assessmentText", value);
                    }}
                    isRequired
                    />
                    <div className="question-error error-msg">
                    <ErrorMessage name="isQuestionAlreadyDefinedErrorMessage" />
                    </div>
                    </>
                    ) : null}
              <AssessmentFormBlock>
                  {values.assessmentTypeId === AssessmentType?.ADAPTIVE && (
                      <div className="option-container">
                      {renderOptions(setFieldValue, values)}
                      <div className="is-correct-error error-msg">
                      <ErrorMessage name="isCorrectErrorMessage" />
                      </div>
                      </div>
                      )}
              </AssessmentFormBlock>
              {!isReadOnly() && <div className="assessment-submit">
                <SecondaryButton
                    onClick={handleCancel}
                    style={{ marginRight: 15 }}
                >
                  Cancel
                </SecondaryButton>

                <PrimaryButton type="submit">
                    {detailForm?.mode === "edit" ? "Update" : "Save"}
                </PrimaryButton>
              </div>}
            </div>
          </AssessmentFormWrapper>
        );
      }}
    </Formik>
  );
};

const AssessmentFormWrapper = styled(Form)`
  padding: 5px 10px 0px 10px;
  .MuiFormControl-root {
    label {
      font-size: 14px;
    }
	label + .MuiInput-formControl {
		margin-top: 26px;
	}
  }
  label {
      font-size: 14px;
      padding-bottom: 5px;
  }
  .assessment-submit {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .error-msg {
    font-size: 12px;
    color: #FA1565;
  }
  .question-error {
    margin-top: -20px;
    margin-bottom: 10px;
  }
  .option-container {
    width: 100%;
    padding-top: 10px;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    .option {
      width: calc(50% - 10px);
      position: relative;
      .is-correct {
          position: absolute;
          right: -15px;
          top: -2px;
          .MuiButtonBase-root {
            padding: 5px
          }
          .MuiTypography-root {
            font-size: 14px
          }
          .MuiSvgIcon-root {
            width: 20px;
            height: 20px;
          }
          & + div {
            padding-bottom: 15px
          }
      }
      &:last-child {
        .is-correct {
          & + div {
            padding-bottom: 0
          }
        }
      }
      &.left {
        margin-right: 10px;
      }
      &.right {
        margin-left: 10px;
      }
    }
    .is-correct-error {
        position: absolute;
        bottom: 30px;
        right: 0;
    }
  }  
`;

const AssessmentFormBlock = styled.div`
  display: flex;
  padding-bottom: 10px;
  & > div {
    margin-right: 15px;
    &:last-child {
      margin-right: 0px;
    }
  }
`;

const MoreContent = styled.div`
  .more-content {
    padding: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    .auto-generate {
        margin-bottom: 15px;
    }
    .auto-generate, .export-container, button {
        width: 100%;
    }
  }
`;

export const Skill_Assessment_Table_Data = [
  {
    colKey: "assessmentType",
    colValue: "Assessment Type",
    width: 200,
    sortable: true,
  },
  {
    colKey: "assessmentSubType",
    colValue: "Assessment Sub Type",
    width: 240,
    sortable: true,
  },
  {
    colKey: "evaluator",
    colValue: "Evaluator",
    sortable: true,
    width: 160,
  },
  {
    colKey: "assessmentText",
    colValue: "Assessment Question",
    sortable: true,
    searchable: true,
  },
  {
    colKey: "levelName",
    colValue: "Proficiency Level",
    width: 200,
    sortable: true,
  }
];

export const Skill_Assessment_ReadOnly_Table =[
  ...Skill_Assessment_Table_Data,
  {
    colKey: "buttonReadOnly",
    saveBtn: false,
    colValue: "",
    dynamic: false,
    width: 40,
  }
]

export const Skill_Assessment_Table = [
  ...Skill_Assessment_Table_Data,
  {
    colKey: "buttonEdit",
    saveBtn: false,
    colValue: "",
    dynamic: false,
    width: 40,
  },
  {
    colKey: "buttonDelete",
    colValue: "",
    dynamic: false,
    width: 40,
  },
];

export default SkillAssessment;
