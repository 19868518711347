import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Formik, Form } from "formik";
import { PrimaryButton } from "../../../Common/Buttons/PrimaryButton";
import { SkillService } from "../../../../services/SkillService";
import useFullLoader from "../../../../Hooks/useFullLoader";
import useAlert from "../../../../Hooks/useAlert";
import TabCustom from "../../../Common/TabCustom";
import TabPanel from "../../../Common/TabPanel";
import CustomizedTables from "../../../Common/CustomizedTables";
import {
  Skill_Proficiencies_Table,
  Skill_ContentItems_Table,
  Skill_Proficiencies_TableOnClient,
} from "./SkillContentTable";
import FormTextField from "../../../Common/FormElements/FormTextField";
import {
  addSkillValidation,
  updateSkillValidation,
} from "../../../../validations/personaValidation";
import AutoSelect from "../../../Common/FormElements/AutoSelect";
import SkillNamePoper from "./SkillNamePoper";
import AutoSelectMulti from "../../../Common/FormElements/AutoSelectMulti";
import AutoSearchForm from "../../../Common/FormElements/AutoSearchForm";
import useRoles from "../../../../Hooks/useRoles";
import PaginationCustom, { CountInit } from "../../../Common/PaginationCustom";
import COLORS from "../../../../assets/colors";
import { H5 } from "../../../Common/Typography";
import ModalPopup from "../../../Common/ModalPopup";
import SkillContentDetail from "../../SkillManagement/SkillContentDetail";
import { useSkillTableData } from "../../../../Provider/SkillTableProvider";
import { AlertType } from "../../../../enums/AlertType";
import { AssessmentType } from "../../../../enums/AssessmentType";
import { SortString } from "../../../../helpers/SortString";
import SkillAssessment from "../../SkillManagement/SkillAssessment";

const tabTitle = ["Proficiencies", "Content", "Assessments"];

export default ({
  data,
  list = [],
  listProficiency,
  closePopup,
  addForm = false,
  getCurrentItem,
  updateSkillArray = () => {},
  withCilentID = false,
  clientView = false,
  levelMenuRoles = []
}) => {
  const { setFullLoader } = useFullLoader();
  const { showAlert } = useAlert();
  const { clientName } = useRoles();
  const {
    contentDetailModal,
    setContentDetailModal,
    setContentDetailData,
  } = useSkillTableData();

  const [currentTab, setCurrentTab] = useState(0);
  const [skillList, setSkillList] = useState([]);
  const [skillContentItems, setSkillContentItems] = useState([]);
  const [currentItem, setCurrentItem] = useState(null);

  const [contentSort, setContentSort] = useState(null);
  const [contentFilters, setContentFilters] = useState(null);
  const [contentLoading, setContentLoading] = useState(false);

  const [skillAssessmentList, setSkillAssessmentList] = useState([]);
  const [skillAssessmentListCopy, setSkillAssessmentListCopy] = useState([]);

  // Pagination
  const [pageOffset, setPageOffset] = useState(0);
  const [rowCount, setrowCount] = useState(CountInit);
  const handlePagination = (e, page) => setPageOffset((page - 1) * rowCount);
  const changeRowCount = (count) => setrowCount(count);
  // Pagination

  useEffect(() => {
    getContentItems(rowCount, pageOffset, false);
  }, [pageOffset, rowCount]);

  useEffect(() => {
    setPageOffset(0);
    getContentItems(rowCount, 0, true);
  }, [contentSort, contentFilters]);

  useEffect(() => {
    if(data?.skillId){
      getSkillAssessments();
    }
  }, [data]);

  const getSkillAssessments = async () => {
    try{
      const skillAssessmentsResponse = await SkillService.getSkillTypesArrayV3(data.skillId, "assessments");
      const resAssessment = skillAssessmentsResponse.filter(({type}) => 
        type?.assessmentTypeId === AssessmentType.EVALUATION 
        || type?.assessmentTypeId === AssessmentType.ADAPTIVE
      );
      const assessmentListMapped = resAssessment.map((el) => ({
        assessmentType: el?.type?.name,
        assessmentSubType: el?.subType?.name,
        evaluator: el?.evaluatorType?.name || "",
        ...el,
      }));
      const sortLevels = SortString(assessmentListMapped, "levelName") || [];
      setSkillAssessmentList(sortLevels);
      setSkillAssessmentListCopy(sortLevels);
    } catch (error) {
      showAlert(AlertType.ERROR, error, 1500);
    }
  }

  const getContentItems = async (rowCountX, pageOffsetX, contentLoad) => {
    const cilentID = withCilentID ? "clientId=" + clientName + "&" : "";
    setContentLoading(!!contentLoad);
    try {
      const resContentItems = await SkillService.getSkillContentItemsV3(
        "/" + data.skillId,
        "/contentItems?",
        `pagesize=${rowCountX}`,
        `&offset=${pageOffsetX}`,
        cilentID,
        `&showIrrelevantCourses=${false}`,
        !!contentSort
          ? `&sortBy=${contentSort?.sortBy || ""}&sortOrder=${
              contentSort?.sortOrder || ""
            }`
          : "",
        !!contentFilters ? contentFilters : ""
      );

      setSkillContentItems(resContentItems);
      setContentLoading(false);
    } catch (error) {
      console.log(error);
    } finally {
      setContentLoading(false);
    }
  };

  const formSubmit = async (fieldValues) => {
    setFullLoader(true);
    let payload = { ...fieldValues };
    try {
      if (addForm) {
        const { skillName, skillDescription } = payload;
        const {
          skillCategory,
          skillId,
          skillFamily,
          source,
          wikipedia,
        } = currentItem;

        const postData = {
          skill_id: skillId,
          skill: skillName,
          description: skillDescription,
          category: skillCategory,
          // skillFamily,
          // wikipedia,
          // source,
          newSkill: true,
          recommended_proficiency: "",
          client_baseline_proficiency: "",
        };
        updateSkillArray(postData);
        closePopup();
        // await SkillService.addSkillDetail(postData);
        // successhandler();
      }
    } catch (errors) {
      showAlert(errors, "error", 1500);
    } finally {
      setFullLoader(false);
    }
  };

  const successhandler = async () => {
    await setFullLoader(false);
    await showAlert("Successfully skill updated!", "success", 1500);
    closePopup();
  };

  const handleTab = (val) => setCurrentTab(val);
  const handleSelect = (val) => {
    if (!!val) {
      setCurrentItem(val);
      getCurrentItem(val);
    }
  };

  // handleSkillChange = (e, val) => {};

  const handleSkillChange = async (e, searchString) => {
    const postData = {
      searchString,
      searchInDescription: false,
      searchAcrossFields: true,
      skillCategoryList: [],
      skillFunctionList: [],
      skillSourceList: [],
    };
    if (!!searchString) {
      try {
        const res = await SkillService.SearchSkillV3(postData);
        setSkillList(res);
      } catch (errors) {
        console.log(errors);
      }
    }
  };

  const initValue = addForm
    ? {
        skillName: currentItem?.skillName,
        skillDescription: currentItem?.skillDescription,
      }
    : {
        skillDescription: data?.skillDescription,
      };

  console.log(data);
  return (
    <SkillUpdateBlock>
      <Formik
        enableReinitialize={true}
        initialValues={initValue}
        validationSchema={addForm ? addSkillValidation : updateSkillValidation}
        onSubmit={formSubmit}
      >
        <Form>
          {addForm && (
            <AutoSearchForm
              label="Skill Name"
              name="skillName"
              optionLabel="skillName"
              list={skillList}
              PopperComponent={(props) => <SkillNamePoper {...props} />}
              renderOption={(option) => (
                <OptionCutom>
                  <span>{option.skillName}</span>
                  <span>{option.skillDescription}</span>
                  <span>{option.skillFamily}</span>
                  <span>{option.skillCategory}</span>
                </OptionCutom>
              )}
              autoFocus={true}
              optionChange={handleSelect}
              inputChange={handleSkillChange}
            />
          )}
          <FormTextField
            multiline={true}
            disabled={true}
            rows="5"
            rowsMax="1000"
            name="skillDescription"
            label="What is this Skill about?"
          />
          {addForm && <PrimaryButton type="submit">Add Skill</PrimaryButton>}
        </Form>
      </Formik>

      {!addForm && (
        <TabCustom
          tabTitle={tabTitle}
          getTabNumber={handleTab}
          currentTab={currentTab}
        >
          <TabPanel value={currentTab} index={0} style={{ padding: 0 }}>
            <CustomizedTables
              columns={
                clientView
                  ? Skill_Proficiencies_TableOnClient
                  : Skill_Proficiencies_Table
              }
              rowList={listProficiency}
              innerTabel={true}
            />
          </TabPanel>
          <TabPanel value={currentTab} index={1}>
            <CustomizedTables
              columns={Skill_ContentItems_Table}
              rowList={skillContentItems?.contentDetailList}
              innerTabel={true}
              sortAndFilterAPILevel={{ sort: true, filter: true }}
              handleSortTable={(value) => setContentSort(value)}
              filterList={skillContentItems?.filters || []}
              getFilterValues={(value) => setContentFilters(value)}
            />
            {skillContentItems?.total > 10 && !contentLoading ? (
              <PaginationCustom
                pageTotal={skillContentItems?.total}
                handlePagination={handlePagination}
                countRows={rowCount}
                changeRowCount={changeRowCount}
              />
            ) : null}

            {skillContentItems?.total === 0 && !contentLoading ? (
              <H5
                style={{
                  // color: COLORS.BAR_RED,
                  padding: 20,
                  textAlign: "center",
                }}
              >
                {!contentFilters?.length
                  ? "No Learning Content is mapped to this skill"
                  : "Search results are not found"}
              </H5>
            ) : null}
          </TabPanel>
          <TabPanel value={currentTab} index={2}>
              {!!skillAssessmentList && (
                <SkillAssessment
                  list={skillAssessmentList || []}
                  listCopy={skillAssessmentListCopy}
                  setList={setSkillAssessmentList}
                  levelMenu={levelMenuRoles}
                  skillId={data.skillId}
                  proficiencyArray={listProficiency || []}
                  detailData={data}
                  assessmentGenerated={false}
                  readOnly={true}
                />
              )}
          </TabPanel>
        </TabCustom>
      )}

      <ModalPopup
        open={contentDetailModal}
        maxWidth="md"
        closePopup={() => {
          setContentDetailModal(false);
          // setContentDetailData(null);
        }}
        title="Details"
      >
        <SkillContentDetail />
      </ModalPopup>
    </SkillUpdateBlock>
  );
};

const SkillUpdateBlock = styled.div`
  .MuiBox-root {
    padding: 0px;
  }
  .MuiTable-root {
    border-radius: 5px;
    padding: 0px 10px;
  }
`;

const OptionCutom = styled.div`
  display: flex;
  font-size: 12px;
  width: 100%;
  span {
    padding: 10px;
    &:first-child {
      flex: 0 0 15%;
    }
    &:nth-child(2) {
      flex: 0 0 55%;
    }
    &:nth-child(3) {
      flex: 0 0 15%;
    }
    &:last-child {
      flex: 0 0 15%;
    }
  }
`;
