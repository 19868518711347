import React, { useState } from "react";
import clsx from "clsx";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import Box from "@material-ui/core/Box";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import COLORS from "../../../assets/colors";
import InputBox from "../FormElements/InputBox";
import SelectMenu from "../FormElements/SelectMenu";
import { SecondaryButton } from "../Buttons/SecondaryButton";
import { Images } from "../../../assets/images";
import SaveIcon from "@material-ui/icons/Save";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import DeleteIcon from '@material-ui/icons/Delete';
import ToolBar from "../ToolBar";
import { Checkbox } from "@material-ui/core";
import MoreVertIcon from "@material-ui/icons/MoreVert";

import SelectMenuGroup from "../FormElements/SelectMenuGroup";
import styled from "styled-components";
import RecommendPoper from "../../Personas/ArchitectSkills/SkillContent/RecommendPoper";
import RoleSearchPopper from "../../Roles/RoleSearchPopper";
import AutoSelectBox from "../FormElements/AutoSelectBox";
import AutoSelectSearch from "../FormElements/AutoSelectSearch";
import PopHover from "../PopModal/PopHover";
import { Paragraph } from "../Typography";
import ModalPopup from "../ModalPopup";
import ConfirmationButton from "../ConfirmationButton";
import useAlert from "../../../Hooks/useAlert";
import AutoFilterLocal from "../FormElements/AutoFilterLocal";
import { SortString } from "../../../helpers/SortString";
import AutoCompleteCheckBox from "../FormElements/AutoCompleteCheckBox";
import { useTableContext } from "../../../Provider/TableProvider";
import { GenAiButton } from "../Buttons/GenAiButton";
import { isDisprzAtomicSkillSource, isUserAllowed } from "../../../helpers/CommonHelper";
import { SkillStore } from "../../../store/store_state";
import { useStylesCustomizedTheme } from "./useStylesCustomizedTheme";
import useAuth from "../../../Hooks/useAuth";
import { UserType } from "../../../enums/UserType";
import text from "../../../locale.en.json";

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: COLORS.LIGHT_MERON,
    color: COLORS.TITLE_BLUE,
    fontFamily: "inherit",
    fontSize: 14,
    fontWeight: 600,
    verticalAlign: (props) => (props.vAlign ? "top" : "middle"),
    "& .MuiTableSortLabel-icon": {
      color: `${COLORS.PRIMARY_BLACK} !important`,
      opacity: 0.3,
    },
    "& .MuiAutocomplete-inputRoot": {
      paddingRight: "55px !important",
    },
    "& .table-header-col-message": {
      cursor: "pointer",
      textDecoration: "underline",
      fontSize: "12px",
    }
  },
  body: {
    color: COLORS.TEXT_BLUE,
    fontFamily: "inherit",
    fontSize: 12,
    fontWeight: 400,
    border: "none",
    // verticalAlign: "top",
    "&:first-child": {
      borderTopLeftRadius: 4,
      borderBottomLeftRadius: 4,
    },
    "&:last-child": {
      borderTopRightRadius: 4,
      borderBottomRightRadius: 4,
    },
    "& .splitVal": {
      border: `solid 1px ${COLORS.ASH_RED}`,
      borderRadius: 20,
      display: "inline-block",
      padding: "5px 12px",
      marginRight: 10,
      "&.dot": {
        width: 40,
        padding: "4px 0px 4px 0px",
        textAlign: "center",
      },
      "&.dot small": {
        color: COLORS.TEXT_BLUE,
        fontSize: 20,
        lineHeight: "10px",
      },
    },
    "& fieldset": {
      // backgroundColor: "#000000",
    },
    "& fieldset::-webkit-scrollbar": {
      width: "3px",
      height: "3px",
      backgroundColor: "transparent",
      borderRadius: "3px",
    },
    "& fieldset::-webkit-scrollbar-thumb": {
      backgroundColor: COLORS.DARK_BLUE,
      borderRadius: "3px",
    },
    "& fieldset::-webkit-scrollbar-track": {
      boxShadow: "inset 0 0 3px rgba(0, 0, 0, 0.3)",
      backgroundColor: COLORS.BLUE_ASH,
      borderRadius: "3px",
    },
    "& .more_action": {
      backgroundColor: "transparent",
      display: "flex",
      alignItems: "center",
      justifiedContent: "center",
      padding: 5,
      borderRadius: 15,
      transition: "all 0.2s linear",
      "&:hover": {
        backgroundColor: COLORS.ASH_WHITE,
      },
    },
    "& .triggerDrop": {
      borderRadius: 5,
      border: `1px solid ${COLORS.ASH_BORDER}`
    },
    "& .disabledTriggerDrop": {
      "& .MuiAutocomplete-endAdornment": {
        visibility: 'hidden',
        cursor: 'pointer'
      },
      "& .MuiAutocomplete-input": {
        cursor: 'pointer'
      }
    },
    "& .edit-btn": {
      padding: '0 !important',
      minWidth: '40 !important',
      border: "none !important",
      backgroundColor: "unset !important",
      marginRight: "15px !important"
    }
  },
}))((props) => <TableCell {...props} />);

const StyledTableContainer = withStyles((theme) => ({
  root: {
    height: (props) => (props.stickyHeader ? props.tableHeight : "auto"),
    overflowX: "hidden",
    overflowY: (props) => (props.stickyHeader ? "auto" : "hidden"),
    "& .MuiTable-root": {
      padding: (props) => (props.innerTabel ? `0px 10px` : `0px`),
    },
    "&::-webkit-scrollbar": {
      width: "3px",
      height: "3px",
      backgroundColor: "transparent",
      borderRadius: "3px",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: COLORS.DARK_BLUE,
      borderRadius: "3px",
    },
    "&::-webkit-scrollbar-track": {
      boxShadow: "inset 0 0 3px rgba(0, 0, 0, 0.3)",
      backgroundColor: COLORS.BODY_ASH,
      borderRadius: "3px",
    },
  },
}))((props) => <TableContainer {...props} />);

const StyledTableRow = withStyles((theme) => ({
  root: {
    backgroundColor: COLORS.PRIMARY_WHITE,
    cursor: "pointer",
    margin: "6px 0px",
    borderBottom: "solid 1px #ccc",
    "& td:first-child": {
      borderLeft: "solid 4px #fff",
    },
    "&:hover, &.active": {
      backgroundColor: COLORS.PRIMARY_WHITE,
      boxShadow: "0 3px 6px 0 rgba(95, 130, 188, 0.26)",
      "& td:first-child": {
        borderLeft: `solid 4px ${COLORS.PRIMARY_RED}`,
      },
    },
    "&.active": {
      backgroundColor: COLORS.ASH_WHITE,
    },
    "& .MuiTableCell-root": {
      fontFamily: "inherit",
    },
    "&.disabled": {
      opacity: '0.6',
      pointerEvents: 'auto',
    },
  },
}))((props) => <TableRow {...props} />);

// const tableStyle

const useStylesTable = makeStyles({
  table: {
    backgroundColor: "#f0f3f8",
    borderWidth: 0,
    borderSpacing: "0px 8px",
    borderCollapse: "separate",
    minWidth: 700,
  },
});

const useStylesCollaps = makeStyles({
  tableCollasps: {
    borderCollapse: "unset",
    borderSpacing: "0px 1px",
  },
});

const dummyRow = ["-", "-", "-", "-", "-"];

const roleContent = ['roleId1', 'roleId2', 'roleId3'];
const skillContent = ['skillId1', 'skillId2', 'skillId3'];

export default function CustomizedTables({
  columns,
  rowList,
  rowListCopy = [],
  rowClick = () => {},
  columnChange = () => {},
  align = "left",
  currentRow,
  borderCollapse = true,
  handleSelect = () => {},
  switchClick = () => {},
  editClick = () => {},
  saveClick = () => {},
  deleteClick = () => {},
  cancelClick = () => {},
  columnBlur = () => {},
  handleCheckBox = () => {},
  handleCheckAll = () => {},
  moreActionClick = () => {},
  selectItem = null,
  selectItemKey = "",
  stickyHeader = false,
  tableHeight = "auto",
  innerTabel = false,
  levelChange = () => {},
  toggleChange = () => {},
  triggerSearchApi = () => {},
  selectedValue = () => {},
  triggerApi = () => {},
  deleteConfirm = false,
  deleteConfirmText = "",
  sortList = "",
  defaultSortColumn = "",
  filterChange = () => {},
  filterChangeText = () => {},
  resetList = () => {},
  resetRow = () => {},
  saveAndCancel = false,
  sortAndFilterAPILevel = {},
  handleSortTable = () => {},
  filterList = [],
  getFilterValues = () => {},
  noData = false,
  noDataToShow = "No Data To Show",
  noDataTabelHeight = "300px",
  theme = "",
  handleAiGenerator = () => {},
  handleSkillRolePopUp = () => {},
  staticFilterList = [],
  handleHeaderMessageClick = () => {}
}) {
  const { showAlert } = useAlert();
  const { userDetails } = useAuth();
  const { User_Type } = userDetails;
  const { sortAndFilterValues, setSortAndFilterValues } = useTableContext();
  const classesTable = useStylesTable();
  const classesCollaps = useStylesCollaps();
  const customizedTheme = useStylesCustomizedTheme();

  const customizedClasses = {
    "customizedTheme": customizedTheme.table
  }
  const { sort, filter } = sortAndFilterAPILevel;

  // Order doesn't matter
  const className = clsx(
    !theme ? classesTable.table : null,
    !borderCollapse ? classesCollaps.tableCollasps : null
  );

  const skillSourceId = SkillStore.useState((s) => s.sourceId);
  const [infoElement, setInfoElement] = useState(null);
  const [currenPopoverText, setCurrenPopoverText] = useState(null);

  const [confirmPopup, setConfirmPopup] = useState(false);
  const [confirmType, setConfirmType] = useState(null);
  const [confirmTitle, setConfirmTitle] = useState("");
  const [currentRowDetail, setCurrentRowDetail] = useState(null);

  //sorting
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState(defaultSortColumn);
  const [filterBy, setFilterBy] = useState("");
  const [filterByListKey, setFilterByListKey] = useState([]);
  const [filterValueSelected, setFilterValueSelected] = useState({});

  // useEffect(() => {
  //   return () => {
  //     resetHeader();
  //   };
  // }, []);

  const resetHeader = () => {
    setOrder("asc");
    setOrderBy(defaultSortColumn);
    setFilterBy("");
    setFilterByListKey([]);
    setFilterValueSelected({});
    setSortAndFilterValues(null);
  };

  const handleRequestSort = (event, property) => {
    if (rowListCopy?.length > 0) {
      const isAsc = orderBy === property && order === "asc";
      setOrder(isAsc ? "desc" : "asc");
      sortList(property, isAsc ? "desc" : "asc");
      setSortAndFilterValues({ ...sortAndFilterValues, orderBy, order });
      if (property !== orderBy) {
        resetList();
        setFilterBy("");
        setSortAndFilterValues({
          ...sortAndFilterValues,
          filterBy: "",
          filterValue: null,
        });
      }
      setOrderBy(property);
    }
  };

  const handleSortAPILevel = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
    handleSortTable({ sortOrder: isAsc ? "desc" : "asc", sortBy: property });
  };

  const handleRequestFilter = (event, colKey) => {
    setFilterBy(filterBy === colKey ? "" : colKey);
    setSortAndFilterValues({
      ...sortAndFilterValues,
      filterBy: filterBy === colKey ? "" : colKey,
      filterValue:
        filterBy === colKey ? "" : sortAndFilterValues?.filterValue || "",
    });
    filterBy === colKey && resetList();
  };

  const handleFilterAPILevel = (colKey) => {
    setFilterByListKey((prev) =>
      prev.includes(colKey)
        ? prev.filter((el) => el !== colKey)
        : [...prev, colKey]
    );

    // remove filter key by close icon
    if (filterByListKey.includes(colKey)) {
      const { [colKey]: filterByCol, ...restObj } = filterValueSelected;
      setFilterValueSelected(restObj);
      makeFilterValues(restObj);
    }
  };

  const handleYes = () => {
    if (confirmType === "delete") {
      closePopup();
      deleteClick(currentRowDetail);
    } else if (confirmType === "update") {
      // formSubmit();
    }
  };

  const handleNo = () => closePopup();
  const closePopup = () => {
    setConfirmPopup(false);
    setConfirmType(null);
    setConfirmTitle("");
    setCurrentRowDetail(null);
  };

  const ConfirmModal = () => (
    <ModalPopup
      open={confirmPopup}
      maxWidth="sm"
      title={confirmTitle}
      center={true}
    >
      <ConfirmationButton yesClick={handleYes} noClick={handleNo} />
    </ModalPopup>
  );

  const deleteTrigger = (e, row, i) => {
    e.stopPropagation();
    if (!!currentRow) {
      showAlert("Kindly add or update the existing record!", "info", 1500);
    } else {
      setCurrentRowDetail(row);
      setConfirmPopup(true);
      setConfirmType("delete");
      setConfirmTitle(deleteConfirmText);
    }
  };

  const handleFilterChange = (val = "", el) => {
    const filterVal = {
      ...filterValueSelected,
      [el.colKey]: typeof val === "string" ? [{ label: val, value: val }] : val,
    };
    setFilterValueSelected(filterVal);
    makeFilterValues(filterVal);
  };

  const makeFilterValues = (valObj) =>
    getFilterValues(
      Object.entries(valObj)
        .flat(Infinity)
        .map((el) => (typeof el === "object" ? el.value : `&${el}=`))
        .join(",")
        .replaceAll("=,", "=")
        .replaceAll(",&", "&")
    );

  const generateContentLink = (url, row, el) => {
    const allowedUsers = [UserType.GENERAL_USER, UserType.GENERAL_USER_INTERNAL];
    return (
      <a
        href={ isUserAllowed(User_Type, allowedUsers) ? "javascript:void(0)"  : url}
        target={isUserAllowed(User_Type, allowedUsers) ? ""  : "_blank"}
        rel="noopener noreferrer"
        style={{ color: COLORS.PRIMARY_WHITE, textDecoration: 'underline' }}
        onClick={(e)=>{
          e.stopPropagation();
          (isUserAllowed(User_Type, allowedUsers))
            && handleSkillRolePopUp(row[el.colId], row)
        }}
        className={row?.disableRow ? "disable-link" : ''} 
      >
        {row[el.colKey]}
      </a>
    );
  }


  const renderRole = (el,row) => {
    return generateContentLink(`/role/edit/${row[el.colId]}`, row, el);
  };

  const renderSkill = (el, row) => {
    return generateContentLink(`/skill/edit/${row[el.colId]}`, row, el);
  };

  const renderConditions = {
    column_render: (el, row) => el.RenderColumn(row),
    selectedRole: (el, row) => generateContentLink(`/role/edit/${row.selectedRoleId}`, row, el),
    selectedSkill: (el, row) => generateContentLink(`/skill/edit/${row.selectedSkillId}`, row,  el),
    skillId: (el, row) => generateContentLink(`/skill/edit/${row.skillId}`, row, el),
    default: (el, row) => (el.colType !== "chip" ? row[el.colKey] : ""),
  };
  
  const renderCellContent = (el, row) => {
    const renderFunction = renderConditions[el.colType] || renderConditions.default;
    return renderFunction(el, row);
  };

  const renderChipContent = (el, row) => {
    if(roleContent.includes(el.colId)){
      return renderRole(el, row);
    } else if(skillContent.includes(el.colId)){
      return renderSkill(el,row);
    } return ''
  }

  return (
    <StyledTableContainer
      stickyHeader={stickyHeader}
      tableHeight={tableHeight}
      innerTabel={innerTabel}
      className={customizedClasses[theme]}
    >
      <Table
        className={className}
        aria-label="customized table"
        stickyHeader={stickyHeader}
      >
        <TableHead>
          {!!rowList ? (
            <TableRow>
              {columns.map((el, i) => (
                <StyledTableCell
                  key={i}
                  align={align}
                  style={{
                    width: el.width,
                    padding:
                      el.colKey === "buttonEdit" ||
                      el.colKey === "buttonDelete" ||
                      el.colKey === "skillMatch" ||
                      el.colKey === "buttonReadOnly" || 
                      el.switchAble === true
                        ? 0
                        : 15,
                  }}
                  sortDirection={orderBy === el.colKey ? order : false}
                  vAlign={!!sortList || !!filterByListKey.length}
                >
                  {
                    <>
                      {el.colType === "aiDescription" ? (
                        el.includeGenAiButton && isDisprzAtomicSkillSource(skillSourceId) ? (
                          <>
                            {el.colValue}
                            <GenAiButton
                              style={{ marginLeft: '10px' }}
                              onClick={() => handleAiGenerator(el)}
                            >
                              Generate Using AI
                            </GenAiButton>
                          </>
                          ) : <>{el.colValue}</>
                        ) :  el.colValue === "checkbox" ? (
                        <Checkbox
                          checked={rowList.length > 0 && rowList.every((row) => row.checked)}
                          onChange={(e) => handleCheckAll(e, el, i)}
                          color="primary"
                          inputProps={{
                            "aria-label": "secondary checkbox",
                          }}
                          style={{ marginLeft: 4, visibility: rowList.every((row) => !!row.disableRow) ? "hidden" : "visible" }}
                        /> //rowListCopy?.length > 0
                      ) : el.sortable ? (
                        <>
                          <TableSortLabel
                            active={orderBy === el.colKey}
                            direction={orderBy === el.colKey ? order : "asc"}
                            onClick={(e) =>
                              sort
                                ? handleSortAPILevel(e, el.colKey)
                                : handleRequestSort(e, el.colKey)
                            }
                          >
                            <div>{el.colValue}</div>
                          </TableSortLabel>
                          {(!!filter &&
                            filterList?.some(
                              (list) => list.name === el.colKey
                            )) ||
                          !filter ||
                          !!el.searchable ? (
                            <FiletrIcon
                              onClick={(e) =>
                                filter
                                  ? handleFilterAPILevel(el.colKey)
                                  : handleRequestFilter(e, el.colKey)
                              }
                              active={
                                filterBy === el.colKey ||
                                filterByListKey.includes(el.colKey)
                              }
                              title={
                                filterBy === el.colKey ||
                                filterByListKey.includes(el.colKey)
                                  ? "Clear"
                                  : "Open Filter"
                              }
                            >
                              <div
                                className={
                                  "hamburger " +
                                  (filterBy === el.colKey ||
                                  filterByListKey.includes(el.colKey)
                                    ? "is-active"
                                    : "")
                                }
                                id="hamburger-1"
                              >
                                <span className="line">&nbsp;</span>
                                <span className="line">&nbsp;</span>
                                <span className="line">&nbsp;</span>
                              </div>
                            </FiletrIcon>
                          ) : null}
                          {/* filterValueSelected[el.colKey] ||  */}
                          {(filterByListKey.includes(el.colKey)) && (
                            <FilterBox>
                              {!el.searchable ? 
                                filterList?.find((list) => list.name === el.colKey)?.options?.length > 0 && 
                                (
                                <AutoCompleteCheckBox
                                  width={el.width + "px"}
                                  list={
                                    filterList?.find(
                                      (list) => list.name === el.colKey
                                    )?.options || []
                                  }
                                  optionLabel={"label"}
                                  optionChange={(val) =>
                                    handleFilterChange(val, el)
                                  }
                                  classSelector="table-filter-box"
                                />
                              ) : (
                                <InputBox
                                  onChange={(e) => {
                                    handleFilterChange(e.target.value, el);
                                  }}
                                  className="searchBoxColumn"
                                />
                              )}
                            </FilterBox>
                          )}
                          {filterBy === el.colKey && !filter ? (
                            <div className="filter-box">
                            <FilterBox>
                              {!!el.searchable ? (
                                <>
                                  <InputBox
                                    onChange={(e) => {
                                      filterChangeText(
                                        e.target.value,
                                        el.colKey,
                                        rowListCopy,
                                        i
                                      );
                                    }}
                                    className="searchBoxColumn"
                                    autoFocus
                                  />
                                </>
                              ) : (
                                <AutoFilterLocal
                                  list={
                                    !el.staticFilter
                                      ? [
                                          ...new Set(
                                            SortString(
                                              staticFilterList?.length > 0 ? [...staticFilterList] : [...rowListCopy],
                                              el.colKey,
                                              order
                                            ).map((elem) => elem[el.colKey])
                                          ),
                                        ]
                                          .filter((element) => Boolean(element))
                                          .map((element) => ({
                                            label: !!element
                                              ? element
                                              : "Blank",
                                            value: !!element
                                              ? element
                                              : "Blank",
                                          }))
                                      : el.staticFilter
                                    // map((element) => ({
                                    //   label: !!element ? element : "Blank",
                                    //   value: !!element ? element : "Blank",
                                    // }))
                                  }
                                  filterLabel="label"
                                  style={{ padding: "0px", width: "100%" }}
                                  placeholder="Filter by Column"
                                  optionChange={(val, reason) => {
                                    console.log(650, val, reason);
                                    filterChange(
                                      val,
                                      reason,
                                      el.colKey,
                                      rowListCopy,
                                      i
                                    );
                                    setSortAndFilterValues({
                                      ...sortAndFilterValues,
                                      filterBy: el.colKey,
                                      filterValue: val,
                                    });
                                    console.log(val, el.colKey);
                                  }}
                                  autoFocus={true}
                                />
                              )}
                            </FilterBox>
                            </div>
                          ) : null}
                        </>
                      ) : (
                        <>
                          <span>{el.colValue}</span>
                          {!!el.infoText && (
                            <InfoOutlinedIcon
                              aria-owns={
                                !!infoElement ? "simple-popover" : undefined
                              }
                              aria-haspopup="true"
                              onMouseEnter={(e) => {
                                setInfoElement(e.currentTarget);
                                setCurrenPopoverText(el.infoText);
                              }}
                              onMouseLeave={() => {
                                setCurrenPopoverText(null);
                                setInfoElement(null);
                              }}
                              style={{
                                cursor: "pointer",
                                fontSize: 18,
                                position: "relative",
                                top: 5,
                                marginLeft: 5,
                              }}
                            />
                          )}
                          <div 
                            className="table-header-col-message" 
                            onClick={handleHeaderMessageClick} 
                          >
                            {el?.colMessage}
                          </div>
                        </>
                      )}
                    </>
                  }
                </StyledTableCell>
              ))}
            </TableRow>
          ) : (
            <TableRow>
              <StyledTableCell align={align}>&nbsp;</StyledTableCell>
            </TableRow>
          )}
        </TableHead>
        <TableBody>
          {!!rowList
            ? rowList?.map((row, i) => (
                <StyledTableRow
                  key={i}
                  className={`${
                      !!selectItem && row[selectItemKey] === selectItem[selectItemKey] ? "active" : ""
                    } 
                    ${row?.disableRow ? "disabled" : ""}`
                    }
                  title={row?.disableRow ? (row?.disableRowTitle || "Already added to skill list")  : ''}
                >
                  {columns.map((el, idx) => (
                    <StyledTableCell
                      key={idx}
                      align={el.colKey === "button" ? "right" : align}
                      onClick={(e) =>
                        el.colKey !== "checkbox" && currentRow !== i && el.colType !== "levelSelect"
                          ? rowClick(row, i, e)
                          : undefined
                      }
                      style={{
                        maxWidth: el.width,
                        padding:
                          el.colKey === "buttonEdit" ||
                          el.colKey === "buttonDelete" ||
                          el.colKey === "skillMatch" ||
                          el.colKey === "buttonReadOnly" ||
                          !!el.switchAble
                            ? "15px 0px"
                            : 15,
                      }}
                    >
                      {!!el.dynamic && currentRow === i ? (
                        <>
                          {!!el.select ? (
                            <>
                              {el.colKey === "subsector" ? (
                                <SelectMenuGroup
                                  value={row[el.colKey]}
                                  list={el.selectMenu}
                                  handleSelect={(e) =>
                                    handleSelect(e, el, el.colKey, i, row)
                                  }
                                />
                              ) : (
                                <SelectMenu
                                  value={
                                    row[
                                      !!el.labelValue
                                        ? el.labelValue
                                        : el.colKey
                                    ]
                                  }
                                  list={el?.selectMenu}
                                  handleSelect={(e) =>
                                    handleSelect(
                                      e,
                                      el,
                                      !!el.labelValue
                                        ? el.labelValue
                                        : el.colKey,
                                      i,
                                      row
                                    )
                                  }
                                  disabled={el?.selectMenu?.length === 0}
                                />
                              )}
                            </>
                          ) : (
                            <InputBox
                              style={{ paddingBottom: 0 }}
                              multiline={true}
                              rows="1"
                              rowsMax="1000"
                              value={row[el.colKey]?.replace(/^\s+/g, "")}
                              onChange={(e) =>
                                columnChange(e, row, el.colKey, i)
                              }
                              onBlur={(e) => columnBlur(e, row, el.colKey, i)}
                            />
                          )}
                        </>
                      ) : (
                        <>
                          {/* {!!el.switchAble && (
                            <ToolBar title="Relevance" arrow={true}>
                              <Switch
                                checked={row[el.colKey]}
                                onChange={(e) =>
                                  switchClick(e, el, el.colKey, i, row)
                                }
                                name={"checked" + i}
                                color="primary"
                                size="small"
                              />
                            </ToolBar>
                          )} */}
                          {el.colType === "chip" && (
                            <ToolBar title={row?.idToDisable?.includes(row[el?.colId]) ? text.skill.suggestedSkillIsPresent : ''} arrow={true}>
                              <div
                                    className={`chip ${(row[el.colKey] === row.selectedRole || row[el.colKey] === row.selectedSkill) ? "selected" : ""} ${row?.idToDisable?.includes(row[el?.colId]) ? "disabled-chip" : ''}`}
                                    key={i + row.clientId}
                                    onClick={() => !row?.idToDisable?.includes(row[el?.colId]) && toggleChange(row[el.colKey], row.clientId, row[el.colId])}
                                >
                        
                                  {!!row[el.colKey] && <span className={"ellipsis"} title={!row?.idToDisable?.includes(row[el?.colId]) ? row[el.colKey] : ''}>
                                  {renderChipContent(el, row)}
                                  </span>}
                                  {!!row[el.highLighColKey] && <span className={"highlight"}>{row[el.highLighColKey]}</span>}
                              </div>
                            </ToolBar>
                          )}
                          {el.colType === "autoSelect" && (
                              <div
                                  className="triggerDropSearch"
                                  key={i + row.clientId}
                              >
                                <AutoSelectSearch
                                    width={el.width}
                                    freeSolo={true}
                                    optionChange={(item) =>
                                        !!item && selectedValue(item, row.clientId, true)
                                    }
                                    optionLabel={el.colKey === "searchSkills" ? "skillName" : "roleName"}
                                    stateValue={row[el.colKey]}
                                    triggerApi={(val, reason) => triggerSearchApi(val, reason)}
                                    PopperComponent={(props) => (
                                        <RoleSearchPopper {...props} toFetchWithoutTitle={true} width={el.width} />
                                    )}
                                    renderOption={(option) => (
                                        <OptionCutom>
                                          <span>{el.colKey === "searchSkills" ? option?.skillName : option?.roleName}</span>
                                        </OptionCutom>
                                    )}
                                />
                              </div>
                          )}
                          {el.colKey === "buttonEdit" || el.colKey ===  "buttonReadOnly" ? (
                            <>
                              {currentRow !== i ? (
                                <SecondaryButton
                                  onClick={(e) => editClick(e, row, i)}
                                  style={{
                                    padding: 0,
                                    minWidth: 40,
                                    border: "none",
                                  }}
                                >
                                  <ToolBar title={el.colKey === "buttonEdit" ? "Edit" : "View"} arrow={true}>
                                    <img
                                      src={el.colKey === "buttonEdit" ? Images.icons.editIcon : Images.icons.viewIcon}
                                      alt={el.colKey === "buttonEdit" ? "edit" : "view"}
                                    />
                                  </ToolBar>
                                </SecondaryButton>
                              ) : (
                                <div style={{ display: "flex" }}>
                                  <SecondaryButton
                                    onClick={(e) => saveClick(e, row, i)}
                                    style={{
                                      padding: 0,
                                      minWidth: 40,
                                      border: "none",
                                    }}
                                  >
                                    <ToolBar title="Save" arrow={true}>
                                      <SaveIcon fontSize="small" />
                                    </ToolBar>
                                  </SecondaryButton>
                                  {!!saveAndCancel && (
                                    <SecondaryButton
                                      onClick={(e) => cancelClick(e, row, i)}
                                      style={{
                                        padding: 0,
                                        minWidth: 40,
                                        border: "none",
                                      }}
                                    >
                                      <ToolBar title="Cancel" arrow={true}>
                                        <img
                                          src={Images.icons.cancelIcon}
                                          alt="cancel"
                                        />
                                      </ToolBar>
                                    </SecondaryButton>
                                  )}
                                </div>
                              )}
                            </>
                          ) : (
                            <>
                              {el.colKey === "buttonDelete" ? (
                                <>
                                  {currentRow !== i ? (
                                    <SecondaryButton
                                      onClick={(e) =>
                                        deleteConfirm
                                          ? deleteTrigger(e, row, i)
                                          : deleteClick(e, row, i)
                                      }
                                      style={{
                                        padding: 0,
                                        minWidth: 40,
                                        border: "none",
                                        backgroundColor: `${el.toIncludeWhiteIcon && "unset"}`,
                                        marginRight: `${el.toIncludeWhiteIcon && "15px"}`
                                      }}
                                      className="delete-icon"
                                    >
                                      <ToolBar title="Delete" arrow={true}>
                                        {el.toIncludeWhiteIcon ? <DeleteIcon style={{ color: 'white' }}/> : <img src={Images.icons.trashIcon} alt="delete" />}
                                      </ToolBar>
                                    </SecondaryButton>
                                  ) : (
                                    <SecondaryButton
                                      onClick={(e) => cancelClick(e, row, i)}
                                      style={{
                                        padding: 0,
                                        minWidth: 40,
                                        border: "none",
                                      }}
                                    >
                                      <ToolBar title="Cancel" arrow={true}>
                                        <img
                                          src={Images.icons.cancelIcon}
                                          alt="cancel"
                                        />
                                      </ToolBar>
                                    </SecondaryButton>
                                  )}
                                </>
                              ) : (
                                <>
                                  {el.colKey === "checkbox" ? (
                                    <Checkbox
                                      checked={!!row.checked}
                                      onChange={(e) =>
                                        handleCheckBox(e, row, i)
                                      }
                                      color="primary"
                                      inputProps={{
                                        "aria-label": "secondary checkbox",
                                      }}
                                      style={{visibility: row?.disableRow ? 'hidden' : 'visible'}}
                                    />
                                  ) : (
                                    <>
                                      {el.colKey === "more_action" ? (
                                        <div className="more_action">
                                          <MoreVertIcon
                                            color="action"
                                            onClick={(e) =>
                                              moreActionClick(e, row, i)
                                            }
                                          />
                                        </div>
                                      ) : (
                                        <>
                                          {!!el.editable &&
                                          el.colType === "levelSelect" ? (
                                            <ToolBar title={!!row?.disableLevelDrop ? 'Select skill to choose proficiency level' : ''} arrow={true} >
                                              <div
                                                className={`${!!row?.aiSuggestedLevel || !!row?.disableLevelDrop ? 'disabledTriggerDrop' : 'triggerDrop'}`}
                                                key={i + row.skillId}
                                              >
                                                <AutoSelectBox
                                                  width={230}
                                                  optionChange={(item, reason) =>
                                                    levelChange(item, row, reason)
                                                  }
                                                  optionLabel="level"
                                                  valueLabel="levelId"
                                                  stateValue={!!row?.aiSuggestedLevel ? !!row?.disableLevelDrop : row[el.colKey]}
                                                  triggerApi={() =>
                                                    triggerApi(row.skillId, row)
                                                  }
                                                  PopperComponent={(props) => (
                                                    <RecommendPoper {...props} />
                                                  )}
                                                  noOptionsText={el.noOptionsText}
                                                  renderOption={(option) => (
                                                    <OptionCutom>
                                                      <span>{option.level}</span>
                                                      <span>
                                                        {option.description}
                                                      </span>
                                                    </OptionCutom>
                                                  )}
                                                  disabled={!!row?.aiSuggestedLevel || !!row?.disableLevelDrop}
                                                />
                                              </div>
                                            </ToolBar>
                                          ) : (
                                            <>
                                              {!!el.editable &&
                                              el.colType === "textBox" ? (
                                                <InputBox
                                                  style={{ paddingBottom: 0 }}
                                                  multiline={true}
                                                  rows="1"
                                                  rowsMax="1000"
                                                  value={row[el.colKey]}
                                                  onChange={(e) =>
                                                    columnChange(
                                                      e,
                                                      row,
                                                      el.colKey,
                                                      i
                                                    )
                                                  }
                                                  onBlur={(e) =>
                                                    columnBlur(
                                                      e,
                                                      row,
                                                      el.colKey,
                                                      i
                                                    )
                                                  }
                                                />
                                              ) : (
                                                <>
                                                  {el.colType === "listing" ? (
                                                    <>
                                                      {row[el.colKey]
                                                        ?.length === 0
                                                        ? "No Industries Mapped"
                                                        : row[el.colKey]
                                                            .slice(
                                                              0,
                                                              el.moreCount
                                                            )
                                                            .map(
                                                              (
                                                                colList,
                                                                colListIndex,
                                                                colListArr
                                                              ) => {
                                                                return (
                                                                  <>
                                                                    <span>
                                                                      {
                                                                        colList[
                                                                          el
                                                                            .colListName
                                                                        ]
                                                                      }
                                                                      {colListIndex <
                                                                        colListArr.length -
                                                                          1 &&
                                                                        ", "}
                                                                    </span>
                                                                  </>
                                                                );
                                                              }
                                                            )}
                                                      {row[el.colKey].length >
                                                        el.moreCount && (
                                                        <span
                                                          style={{
                                                            color:
                                                              COLORS.PRIMARY_RED,
                                                          }}
                                                          aria-owns={
                                                            !!infoElement
                                                              ? "simple-popover"
                                                              : undefined
                                                          }
                                                          aria-haspopup="true"
                                                          onMouseEnter={(e) => {
                                                            setInfoElement(
                                                              e.currentTarget
                                                            );
                                                            setCurrenPopoverText(
                                                              row[el.colKey]
                                                                .map(
                                                                  (colList) =>
                                                                    colList[
                                                                      el
                                                                        .colListName
                                                                    ] + ", "
                                                                )
                                                                .slice(
                                                                  el.moreCount
                                                                )
                                                            );
                                                          }}
                                                          onMouseLeave={() => {
                                                            setCurrenPopoverText(
                                                              null
                                                            );
                                                            setInfoElement(
                                                              null
                                                            );
                                                          }}
                                                        >
                                                          {" "}
                                                          +{" "}
                                                          {row[el.colKey]
                                                            .length -
                                                            el.moreCount}{" "}
                                                          more
                                                        </span>
                                                      )}
                                                    </>
                                                  ) : (
                                                    <>
                                                      {el.colType === 
                                                        "inside_Function" ? (
                                                        el.getTableData(row)
                                                      ) : 
                                                      (el.colKey === "skillMatch" && !!row?.selectedRole)? (
                                                        <SecondaryButton
                                                            onClick={(e) =>
                                                             {saveClick(e, el, row)}
                                                            }
                                                            className="edit-btn"
                                                          >
                                                            <ToolBar title="Architect Skills" arrow={true}>
                                                             {!!row?.isSkillMappingViewed 
                                                                ? <img src={Images.icons.tickIcon} alt="tick.png" /> 
                                                                : <img src={Images.icons.boldArrow} alt="bold-arrow.png"/>}
                                                            </ToolBar>
                                                          </SecondaryButton>
                                                      ) : 
                                                      (
                                                        <>
                                                          {" "}{renderCellContent(el, row)}{" "}
                                                        </>
                                                      )}
                                                    </>
                                                  )}
                                                </>
                                              )}
                                            </>
                                          )}
                                        </>
                                      )}
                                    </>
                                  )}
                                </>
                              )}
                            </>
                          )}
                        </>
                      )}
                    </StyledTableCell>
                  ))}
                  {/* {row?.disableRow && (
                    <div className="custom-tooltip">Already added to Skill List</div>
                  )} */}
                </StyledTableRow>
              ))
            : dummyRow.map((row, i) => (
                <StyledTableRow key={i}>
                  <StyledTableCell align={align}>{row}</StyledTableCell>
                </StyledTableRow>
              ))}
         {!!noData &&
          <TableRow>
              <TableCell colSpan={columns.length}>
                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  flexDirection="column"
                  minHeight={noDataTabelHeight}
                  gridGap="10px"
                >
                  <img src={Images.icons.noData} alt="Empty Table" style={{marginRight: "18px", height: "50%"}} />
                  {noDataToShow}
                </Box>
              </TableCell>
            </TableRow>
         }
        </TableBody>
      </Table>

      <PopHover anchorEl={infoElement} hoverType={true}>
        <InfoContent>
          <Paragraph>{currenPopoverText}</Paragraph>
        </InfoContent>
      </PopHover>
      <ConfirmModal />
      <div className="lastRow">&nbsp;</div>
    </StyledTableContainer>
  );
}

const OptionCutom = styled.div`
  display: flex;
  font-size: 12px;
  width: 100%;
  span {
    padding: 10px;
    &:first-child {
      flex: 0 0 30%;
    }
    &:last-child {
      flex: 0 0 70%;
    }
  }
`;

const InfoContent = styled.div`
  padding: 15px;
  width: 350px;
  p {
    font-size: 14px;
    line-height: 20px;
    padding-bottom: 0;
  }
`;

const FiletrIcon = styled.div`
  cursor: pointer;
  display: inline-flex;
  position: relative;
  top: 8px;
  opacity: ${(props) => (props.active ? 1 : 0.3)};
  &:hover {
    opacity: ${(props) => (props.active ? 1 : 0.5)};
  }
  .hamburger .line {
    width: 16px;
    height: 1px;
    background-color: #000;
    display: block;
    margin: 0px auto 3px auto;
    -webkit-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
  }

  .hamburger:hover {
    cursor: pointer;
  }

  /* ONE */

  #hamburger-1 .line:nth-child(2) {
    width: 10px;
  }
  #hamburger-1.is-active .line {
    width: 12px;
  }
  #hamburger-1.is-active .line:nth-child(2) {
    opacity: 0;
  }

  #hamburger-1.is-active .line:nth-child(1) {
    transform: translateY(4px) rotate(45deg);
  }

  #hamburger-1 .line:nth-child(3) {
    width: 4px;
  }

  #hamburger-1.is-active .line:nth-child(3) {
    width: 12px;
    transform: translateY(-4px) rotate(-45deg);
  }
`;
//.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline MuiInputBase-root
const FilterBox = styled.div`
  & > div {
    margin-top: 10px;
    padding-bottom: 0px;
    ${'' /* position: absolute !important;
    width: 100%; */}
    /* MuiTextField-root */
    .MuiInputBase-root {
      background-color: ${COLORS.PRIMARY_WHITE};
    }
    .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
      /* border: none; */
      border: solid 1px ${COLORS.ASH_BORDER};
    }
    .MuiInputBase-input:focus,
    .MuiOutlinedInput-root.Mui-focused {
      background-color: white;
      outline: none;
      box-shadow: none;
      border-color: ${COLORS.ASH_BORDER};
    }
    .MuiSelect-selectMenu {
      padding: 10px 20px 10px 10px;
    }
    .MuiAutocomplete-inputRoot {
      /* background-color: ${COLORS.PRIMARY_WHITE}; */
    }
  }
`;
